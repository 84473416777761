import { Button, CircularProgress, MenuItem, Select, Slider, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { getMartItems, getMartItemsCollections, getSellerMartItems } from "../../Apis/MartApi";
import Navbar from "../../Component/Navbar";
import ProductCard from "../../Component/ProductCard";
import AvatarImg from "../../assets/images/avatar.png";
import {
  Content,
  FieldLabel,
  Heading,
  LoaderCont,
  MainBody,
  ProductList,
  QueryField,
  Sidebar,
  Footer,
  Header,
  UserBannerCont,
  UserInfoCont,
  UserAvatarCont,
  UserAvatar,
  UserName,
  MutedText,
  BioSection,
  CreateCollectionButton,
} from "./MartPage.styles";
import { getUserData } from "../../Apis/UserApi";
import ManageCollectionModal from "../../Component/Modals/ManageCollectionModal";
import { connect } from "react-redux";

let timeOut;

const MartPage = (props) => {
  const { userId } = useParams();

  const [martItems, setMartItems] = useState([]);
  const [martItemsCollections, setMartItemsCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showBio, setShowBio] = useState(false);
  const [userData, setUserData] = useState({});

  const [filters, setFilters] = useState({
    query: null,
    maxPrice: 150,
    sportType: 'All',
  });

  const handleFilterChange = async (name, value) => {
    if(timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      setFilters({ ...filters, [name]: value });
    }, 500);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const collectionsData = await getMartItemsCollections(userId);
    const martItemsData = await getMartItems(filters.query, filters.maxPrice, filters.sportType, userId);
    if(martItemsData) {
      setMartItems(martItemsData);
    }
    if(collectionsData) {
      setMartItemsCollections(collectionsData);
    }
    setIsLoading(false);
  };

  const fetchUserData = async () => {
    setIsLoading(true);
    const data = await getUserData(userId);
    if(data) {
      setUserData(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(filters);
    fetchData();
    if(userId) {
      fetchUserData();
    }
  }, [filters, userId]);

  // Create collection modal

  const [openCreateCollection, setOpenCreateCollection] = useState(false);

  const handleManageCollectionModal = (isOpen) => {
    setOpenCreateCollection(isOpen);
  }

  return (
    <>
      <article>
        <Navbar />

        <MainBody>

          {userId && (
            <Header>
              <UserBannerCont></UserBannerCont>
              <UserInfoCont>
                <UserAvatarCont>
                  <UserAvatar src={AvatarImg} alt="avatar" />
                  <div>
                    <UserName>{userData.name}</UserName>
                    <MutedText>
                      Total SDQLs {`(${userData.mart_items_count || "0"})`}
                    </MutedText>
                    <MutedText
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowBio(!showBio)}
                    >
                      Show Bio
                    </MutedText>
                  </div>
                </UserAvatarCont>

                <BioSection className={`${showBio ? "show" : ""}`}>
                  <MutedText>{userData.bio || "No information given"}</MutedText>
                </BioSection>

                {Number(props?.store?.id) === Number(userId) && (
                  <CreateCollectionButton onClick={() => handleManageCollectionModal(true)}>
                    Create new collection
                  </CreateCollectionButton>
                )}
              </UserInfoCont>
            </Header>
          )}

          <Sidebar>
            <Heading>Filters</Heading>

            <div>
              <QueryField
                label="Enter your query..."
                fullWidth
                // value={filters.query}
                onChange={(e) => handleFilterChange("query", e.target.value)}
              />
            </div>

            <div>
              <FieldLabel>Max Price</FieldLabel>
              <Slider
                defaultValue={filters.maxPrice}
                aria-label="Price"
                valueLabelDisplay="auto"
                min={5}
                max={150}
                // value={filters.maxPrice}
                onChange={(e, value) => handleFilterChange("maxPrice", value)}
                step={5}
              />
            </div>

            <div>
              <FieldLabel>Sport Type</FieldLabel>
              <Select
                fullWidth
                label="Sport Type"
                value={filters.sportType}
                onChange={(e) => handleFilterChange("sportType", e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="NFL">NFL</MenuItem>
                <MenuItem value="NCAAFB">NCAAFB</MenuItem>
                <MenuItem value="NBA">NBA</MenuItem>
                <MenuItem value="NHL">NHL</MenuItem>
                <MenuItem value="MLB">MLB</MenuItem>
                <MenuItem value="NCAABB">NCAABB</MenuItem>
                <MenuItem value="WNBA">WNBA</MenuItem>
                <MenuItem value="NFLPRE">NFLPRE</MenuItem>
                <MenuItem value="ATP">ATP</MenuItem>
                <MenuItem value="CFL">CFL</MenuItem>
                <MenuItem value="UFC">UFC</MenuItem>
                <MenuItem value="UFCW">UFCW</MenuItem>
              </Select>
            </div>
          </Sidebar>

          <Content>
            {isLoading ? (
              <LoaderCont>
                <CircularProgress />
              </LoaderCont>
            ) : (
              <ProductList>
                {martItems.length > 0 ? (
                  <>
                    {martItemsCollections.map((item) => (
                      <ProductCard data={item} isCollection={true} callback={() => fetchData()} />
                    ))}
                    {martItems.map((item) => (
                      <ProductCard data={item} />
                    ))}
                  </>
                ) : (
                  <p>
                    No item to show
                  </p>
                )}
              </ProductList>
            )}
          </Content>

        </MainBody>
        <Footer>
          All sales are final. Refunds are at the sole discretion of GimmeTheDog.com management.
        </Footer>
      </article>

      {Number(props?.store?.id) === Number(userId) && (
        <ManageCollectionModal open={openCreateCollection} setOpen={setOpenCreateCollection} fetchData={fetchData} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

export default connect(mapStateToProps)(MartPage);
