import {
  Box,
  Button,
  Modal,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import React from "react";
import { postSavedQuery, updateSavedQuery } from "../../../Apis/QueryApi";

const CreateQueryModal = ({ fetchData, open, setOpen, tabValue }) => {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (event.target.query.value) {
      const data = {
        query: event.target.query.value,
        description: event.target.description.value,
        sport_type: tabValue,
      };
      try {
        const res = await postSavedQuery(data);
        console.log(res);
        if (res.status === 201) {
          setOpen(false);
          fetchData();
          event.target.query.value = "";
        }
      } catch (error) {
        console.error(error.response);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3 className="fs-3">Create SDQL Form</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            type="text"
            name="query"
            label="SDQL"
            variant="standard"
            required
          />

          <TextareaAutosize
            aria-label="Description"
            minRows={3}
            placeholder="Description"
            name="description"
            style={{
              width: "100%",
              marginBottom: "20px",
              borderRadius: "10px",
              borderColor: "rgba(0, 0, 0, 0.42)",
              padding: "10px",
            }}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateQueryModal;
