import {
    Box, Modal
} from "@material-ui/core";
import React from "react";

const ImportQueryModal = ({
    fetchData,
    open,
    setOpen,
}) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={()=>handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    maxWidth: "800px",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h3 className="fs-3">This feature coming soon</h3>
            </Box>
        </Modal>
    );
};

export default ImportQueryModal;
