import { useState } from 'react';
import { useHistory, Link as LinkDOM } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { URL } from '../Urls';

// const Signup = () => {

//     const history = useHistory()

//     const handleRegister = (e) => {
//         e.preventDefault()
//         if (name === "" || email === "" || password === "" || conPassword === ""){
//             setError("All fields are required")
//         }
//         else if (password !== conPassword){
//             setError("Passwords do not match")
//         }
//         else{
//             axios({
//                 method: "post",
//                 url: "https://sdql-testapp.herokuapp.com/register/",
//                 //url: "http://localhost:8000/register/",
//                 data: {
//                     name: name,
//                     email: email,
//                     password: password,
//                 },
//                 withCredentials: true,
//                 })
//                 .then(res => {
//                 if (res.data.message === "R"){
//                     history.replace("/login")
//                 }
//                 if (res.data.message === "A"){
//                     setError("User Already Exists")
//                 }
//             })
//         }
//     }

//     const [name, setName] = useState("")
//     const [email, setEmail] = useState("")
//     const [password, setPassword] = useState("")
//     const [conPassword, setConPassword] = useState("")
//     const [error, setError] = useState("")

//     return (
//         <div className="signup">
//             {
//                 error && <div class="alert alert-danger" role="alert">
//                 {error}
//                 </div>
//             }
//             <div class="container login-container">
//             <div class="row">
//                 <div class="col-md-6 login-form-2">
//                     <h3>Signup</h3>
//                     <form>
//                         <div class="form-group">
//                             <input type="text" class="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
//                         </div>
//                         <div class="form-group">
//                             <input type="text" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
//                         </div>
//                         <div class="form-group">
//                             <input type="password" class="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
//                         </div>
//                         <div class="form-group">
//                             <input type="password" class="form-control" placeholder="Confirm Password" value={conPassword} onChange={(e) => setConPassword(e.target.value)}/>
//                         </div>
//                         <div className="btn-div">
//                             <div class="form-group">
//                                 <button class="btnSubmit" onClick={(e) => handleRegister(e)}>Sign Up</button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//         </div>
//     );
// }
 
// export default Signup;


















import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Paper } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUp(props) {
    const classes = useStyles();

    const history = useHistory()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [conPassword, setConPassword] = useState("")
    const [error, setError] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [errorPassword, setErrorPassword] = useState("")
    const [errorName, setErrorName] = useState("")
    const [errorConPassword, setErrorConPassword] = useState("")

    const [open, setOpen] = useState(false);

    const [alertState, setState] = useState({
        vertical: "top",
        horizontal: "center"
    });

    const { vertical, horizontal } = alertState;

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    

    const handleRegister = (e) => {
        e.preventDefault()
        setErrorEmail("")
        setErrorName("")
        setErrorPassword("")
        setErrorConPassword("")
        let flag = true
        if (name === ""){
            setErrorName("Name is required")
            flag = false
        }
        if (email === ""){
            setErrorEmail("Email is required")
            flag = false
        }
        if (password === ""){
            setErrorPassword("Password is required")
            flag = false
        }
        if (conPassword === ""){
            setErrorConPassword("Confirm Password is required")
            flag = false
        }
        if(flag && password !== conPassword){
            setError("Passwords do not match")
            flag = false
            setOpen(true)
        }
        if(flag) {
            axios({
                method: "post",
                //url: "https://sdql-testapp.herokuapp.com/register/",
                url: URL + "register/",
                data: {
                    name: name,
                    email: email,
                    password: password,
                },
                withCredentials: true,
                })
                .then(res => {
                if (res.data.message === "R"){
                    history.replace("/login")
                }
                if (res.data.message === "A"){
                    setError("User Already Exists")
                    setOpen(true)
                }
            })
        }
    }

  return (
      <div>
        {
            props.isAuthenticated && history.replace('/')
        }
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3}>
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Sign Up
            </Typography>
            <form className={classes.form} noValidate>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Name"
                    name="name"
                    autoComplete="email"
                    autoFocus
                    error={errorName}
                    helperText={errorName}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={errorEmail}
                    helperText={errorEmail}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={errorPassword}
                    helperText={errorPassword}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    name="confirm password"
                    label="Confirm Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={errorConPassword}
                    helperText={errorConPassword}
                    onChange={(e) => setConPassword(e.target.value)}
                    value={conPassword}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => handleRegister(e)}
                >
                    Sign Up
                </Button>
                <Grid container>
                    <Grid item>
                    <LinkDOM to="/login">
                        Already have an account? Sign in
                    </LinkDOM>
                    </Grid>
                </Grid>
                </form>
        </div>
        </Paper>
        </Container>
        <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = state => {
    return {
      isAuthenticated: state.isAuthenticated
    }
}

export default connect(mapStateToProps)(SignUp);