import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { useEffect, useState } from "react";
import { DualRing } from "react-awesome-spinners";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "../../CSS/Subpage.css";
import Navbar from "../../Component/Navbar";
import { visitAction } from "../../redux/MyActions";
import { getSavedQuery } from "../../Apis/QueryApi";

let interval;

const QueryResultPage = (props) => {
  const [htmlStr, setHtmlStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const [alertState, setState] = useState({
    vertical: "bottom",
    horizontal: "center",
  });

  const { vertical, horizontal } = alertState;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const days = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // const submitQuery = async (query, flag) => {
  //   //console.time('doSomething')
  //   setHtmlStr("");
  //   setLoading(true);
  //   let interID = setInterval(() => {
  //     let value = document.getElementById("myTable1");
  //     if (value) {
  //       setLoading(false);
  //       handleCheck(1);
  //       clearInterval(interID);
  //     }
  //     value = document.getElementById("myTable2");
  //     if (value) {
  //       setLoading(false);
  //       handleCheck(2);
  //       let showMore = document.getElementById("showMore");
  //       if (showMore) {
  //         showMore.onclick = handleShowMore.bind(this);
  //       }
  //       clearInterval(interID);
  //     }
  //     value = document.getElementById("myTable3");
  //     if (value) {
  //       setLoading(false);
  //       handleCheck(3);
  //       clearInterval(interID);
  //     }
  //     value = document.getElementById("invalid-query");
  //     if (value) {
  //       setLoading(false);
  //       //handleCheck(4)
  //       clearInterval(interID);
  //     }
  //   }, 1000);
  //   axios({
  //     method: "post",
  //     //url: "https://sdql-testapp.herokuapp.com/query_result/",
  //     cancelToken: source.token,
  //     url: URL + "query_result/",
  //     data: {
  //       query: query,
  //       sport: sportType,
  //       showComplete: flag,
  //       jwtCookie: props.jwtCookie,
  //     },
  //     //withCredentials: true,
  //   }).then((res) => {
  //     setLoading(false);
  //     if (res.data.error) {
  //       setError(res.data.errorMessage);
  //       setOpen(true);
  //     } else {
  //       setHtmlStr(res.data.htmlString);
  //     }
  //   });
  //   //.then(res => console.log(res))
  // };


  const handleCheck = (tableNo) => {
    let table = document.getElementById("myTable" + tableNo);
    let value = table.getElementsByTagName("th");
    if (tableNo === 1) {
      for (let i = 0; i < value.length; i++) {
        value[i].onclick = sortTable1.bind(this, i);
      }
    } else if (tableNo === 2) {
      for (let i = 0; i < value.length; i++) {
        value[i].onclick = sortTable2.bind(this, i);
      }
    } else {
      for (let i = 0; i < value.length; i++) {
        value[i].onclick = sortTable3.bind(this, i);
      }
    }
  };

  function sortTable1(n, e) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      v1,
      v2,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("myTable1");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        v1 = rows[i].getElementsByTagName("td")[n].innerHTML;
        v2 = rows[i + 1].getElementsByTagName("td")[n].innerHTML;
        let t1 = parseFloat(v1);
        let t2 = parseFloat(v2);
        if (isNaN(t1)) {
          x = v1;
          y = v2;
        } else {
          x = t1;
          y = t2;
        }
        if (dir === "asc") {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  function sortTable2(n, e) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      v1,
      v2,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("myTable2");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        v1 = rows[i].getElementsByTagName("td")[n].innerHTML;
        v2 = rows[i + 1].getElementsByTagName("td")[n].innerHTML;
        if (n === 0) {
          x = new Date(v1);
          y = new Date(v2);
        } else if (n === 2) {
          x = days[v1];
          y = days[v2];
        } else {
          if (n === 1) {
            v1 = v1.replace(":", ".")
            v2 = v2.replace(":", ".")
          }
          let t1 = parseFloat(v1);
          let t2 = parseFloat(v2);
          if (t1) {
            x = t1;
            y = t2;
          } else {
            x = v1;
            y = v2;
          }
        }
        if (dir === "asc") {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  function sortTable3(n, e) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      v1,
      v2,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("myTable3");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        v1 = rows[i].getElementsByTagName("td")[n].innerHTML;
        v2 = rows[i + 1].getElementsByTagName("td")[n].innerHTML;
        let t1 = parseFloat(v1);
        let t2 = parseFloat(v2);
        if (isNaN(t1)) {
          x = v1;
          y = v2;
        } else {
          x = t1;
          y = t2;
        }
        if (dir === "asc") {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  const [saveLoading, setSaveLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState({
    ok: null,
    message: "",
  });

  const params = useParams();
  const queryId = params.queryId;

  const fetchQueryResult = async () => {
    setLoading(true)
    const data = await getSavedQuery(queryId);
    console.log(data)
    setLoading(false)
    setHtmlStr(data?.result?.html_string)
  }

  useEffect(() => {
    fetchQueryResult()
  }, []);

  return (
    <div>
      <Navbar />
      {loading && (
        <div className="loading-anim">
          <DualRing size={100} color="#30aaba" />
        </div>
      )}
      {htmlStr && <div className="result-set" style={{ margin: '120px 20px 50px 20px' }}>{ReactHtmlParser(htmlStr)}</div>}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleClose}
        id="invalid-query"
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: { ...state },
    isAuthenticated: state.isAuthenticated,
    firstVisit: state.firstVisit,
    jwtCookie: state.jwtCookie,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAuthenticate: () => dispatch(visitAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QueryResultPage);
