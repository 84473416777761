import "../CSS/Home.css";
import Navbar from "./Navbar";

const Home = () => {
  return (
    <div>
      <Navbar />
      <div style={{ marginTop: 110, paddingLeft: 20, paddingRight: 20 }}>
      <h3>
          Welcome to GimmeTheDog.com - the next generation in the use of the
          Sports Data Query Language (SDQL)
        </h3>
        <p></p>
        <h5>
          A few thoughts on where things stand:
        </h5>
        <ul>
        <li>The NFL, NCAAFB, NBA, NHL, WNBA, and CFL are fully operational. The UFC data is in and being updated. We're working on getting the user interface going. 
            We have plans to add additonal sports in the near future.</li>
        <li>Documentation for the NFL and MLB is loaded. Each parameter is documented with a working example. Check out the red documentation
            buttons on the NFL and MLB pages. Viewing and understanding the examples is a great way to learn SDQL. Docunentation for the other sports will be available soon</li>
        <li>Many exciting new SDQL parameters and shortcuts are availabile.</li>   
        <li>We have many cool new features that we'll talk about on Twitter.</li>
        <li>SDQL at GimmeTheDog.com is an extremely powerful way to gather unique angles and insights. Information is the key to success. Data-based decision making give you a huge advantage. Our tools give you that edge.</li>
        <li>We have more good stuff coming! </li>    
        </ul>
        <h5>Contact info</h5>
        <ul>
        <li>Email: info@GimmeTheDog.com</li> 
        <li>DM us on Twitter: @GimmeTheDog</li> 
        <li>Follow us on twitter: @GimmeTheDog</li> 
        <li>Join us on our free Discord Channel. Ask questions to advance your SDQL skills: Follow us on twitter: https://discord.gg/Y9WZdun7tP</li> 
        </ul>
        <p></p>

        <h6>Refund policy</h6>
        <ul>
        <li>Since the Service offers non-tangible, irrevocable goods we do not provide refunds after the product is purchased, which you acknowledge prior to purchasing any product on the Services. Please make sure that you’ve carefully read service description before making a purchase.</li>
        <li>If you have any questions, concerns, or complaints regarding this refund policy, we encourage you to contact us at info@gimmethedog.com</li>
        </ul>
        <h6>Disclaimer</h6>

        <p><span class="small">The information provided by GimmeTheDog LLC ("we," "us," or "our") on gimmethedog.com (the "Site") and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK. </span></p>
      </div>
    </div>
  );
};

export default Home;
