import { DO_LOGIN, DO_LOGOUT, VISITED } from './MyTypes'
import Cookies from 'universal-cookie/es6';

const cookies = new Cookies()

const initialState = {
    // isAuthenticated: false,
    // firstVisit: true,
    id: null,
    name: null,
    bio: null,
    email: null,
    canSell: null,
    isStaff: null,

    isAuthenticated: false,
    firstVisit: true,
    jwtCookie: cookies.get('jwt'),
}

const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case DO_LOGIN: return {
            ...state,
            id: action.payload?.id,
            name: action.payload?.name,
            bio: action.payload?.bio,
            email: action.payload?.email,
            canSell: action.payload?.can_sell,
            isStaff: action.payload?.is_staff,

            isAuthenticated: true,
            firstVisit: false,
            jwtCookie: cookies.get('jwt')
        }
        case DO_LOGOUT: return {
            ...state,
            id: null,
            name: null,
            bio: null,
            email: null,
            canSell: null,
            isStaff: null,

            isAuthenticated: false,
            jwtCookie: '',
        }
        case VISITED: return {
            ...state,
            firstVisit: false,
            isAuthenticated: true,
        }
        default: return state
    }
}

export default authReducer