import styled from "styled-components";

export const Body = styled.div`
  background-color: #fff;
  box-shadow: -1px 2px 15px -4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  /* min-height: 300px; */
  min-width: 300px;
  max-width: 350px;
  flex: 0 0 33%;
  padding: 20px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  position: relative;

  overflow: hidden;
`;

export const Heading = styled.h3`
  font-size: 1.5rem;

  & span {
    color: red;
  }

  & small {
    color: #8f8f8f;
  }
`;

export const Description = styled.p`
  font-size: 0.9rem;
  color: #8f8f8f;
  word-wrap: pre-wrap;
  height: 200px;
  overflow-y: auto;
  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: red;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: red;
  }
`;

export const BuyButton = styled.button`
  background-color: red;
  color: #fff;
  max-width: 150px;
  width: 100%;
  margin: 0px auto;
  border-radius: 20px;
`;

export const MainSection = styled.div`
  border-bottom: 1px solid #ececec;
`;

export const SellerInfoSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PointsText = styled.p`
  font-size: 0.9rem;

  & span {
    font-size: 1rem;
    color: red;
  }

  & small {
    color: #8f8f8f;
  }
`;

export const Banner = styled.p`
  /* font-size: 0.9rem; */
  color: #fff;
  position: absolute;
  top: 30px;
  right: -33px;
  margin: 0px;
  background: red;
  padding: 2px 10px;
  transform: rotateZ(45deg);
  width: 150px;
  text-align: center;
`;

export const DeleteBtn = styled.button`
  /* font-size: 0.9rem; */
  color: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0px;
  background: red;
  padding: 10px;
  width: 25px;
  height: 25px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  outline: none;
  border: none;
`;
