import { CircularProgress, MenuItem, Select, Slider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMartItems, getSellerMartItems } from "../../Apis/MartApi";
import Navbar from "../../Component/Navbar";
import ProductCard from "../../Component/ProductCard";
import {
  BioSection,
  Content,
  CustomSelect,
  FieldLabel,
  Header,
  Heading,
  LoaderCont,
  MainBody,
  MutedText,
  ProductList,
  QueryField,
  Sidebar,
  UserAvatar,
  UserAvatarCont,
  UserBannerCont,
  UserInfoCont,
  UserName,
} from "./SellerProfilePage.styles";
import AvatarImg from "../../assets/images/avatar.png";
import { getSellerData, getUserData } from "../../Apis/UserApi";
import { useParams } from "react-router-dom";

let timeOut;

const SellerProfilePage = () => {
  const { sellerId } = useParams();

  const [martItems, setMartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showBio, setShowBio] = useState(false);
  const [userData, setUserData] = useState({});
  const [sellerData, setSellerData] = useState({});
  const [filters, setFilters] = useState({
    query: null,
    maxPrice: 150,
    sportType: 'All',
  });

  const handleFilterChange = async (name, value) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      setFilters({ ...filters, [name]: value });
    }, 500);
  };

  const fetchUserData = async () => {
    const data = await getUserData();
    if (data) {
      console.log(data);
      setUserData(data);
      // setCanSell(data?.can_sell);
    }
  };

  const fetchSellerData = async () => {
    const data = await getSellerData(sellerId);
    if (data) {
      console.log(data);
      setSellerData(data);
      // setCanSell(data?.can_sell);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getSellerMartItems(
      sellerId,
      filters.query,
      filters.maxPrice,
      filters.sportType
    );
    console.log(data);
    if (data) {
      setMartItems(data);
      setMartItems(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(filters);
    fetchData();
  }, [filters]);

  useEffect(() => {
    fetchSellerData();
  }, [sellerId]);

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <article>
      <Navbar />

      <MainBody>
        <Header>
          <UserBannerCont></UserBannerCont>
          <UserInfoCont>
            <UserAvatarCont>
              <UserAvatar src={AvatarImg} alt="avatar" />
              <div>
                <UserName>{sellerData.name}</UserName>
                <MutedText>Total SDQLs {`(${sellerData.mart_items_count || '0'})`}</MutedText>
                <MutedText
                  style={{cursor: 'pointer'}}
                  onClick={() => setShowBio(!showBio)}
                >
                  Show Bio
                </MutedText>
              </div>
            </UserAvatarCont>

            <BioSection className={`${showBio ? "show" : ""}`}>
              <MutedText>
                {sellerData.bio || "No information given"}
              </MutedText>
            </BioSection>
          </UserInfoCont>
        </Header>

        <Sidebar>
          <Heading>Filters</Heading>

          <div>
            <QueryField
              label="Enter your query..."
              fullWidth
              // value={filters.query}
              onChange={(e) => handleFilterChange("query", e.target.value)}
            />
          </div>

          <div>
            <FieldLabel>Max Price</FieldLabel>
            <Slider
              defaultValue={filters.maxPrice}
              aria-label="Price"
              valueLabelDisplay="auto"
              min={5}
              max={150}
              // value={filters.maxPrice}
              onChange={(e, value) => handleFilterChange("maxPrice", value)}
              step={5}
            />
          </div>

          <div>
            <FieldLabel>Sport Type</FieldLabel>
            <Select
              fullWidth
              label="Sport Type"
              value={filters.sportType}
              onChange={(e) => handleFilterChange("sportType", e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="NFL">NFL</MenuItem>
              <MenuItem value="NCAAFB">NCAAFB</MenuItem>
              <MenuItem value="NBA">NBA</MenuItem>
              <MenuItem value="NHL">NHL</MenuItem>
              <MenuItem value="MLB">MLB</MenuItem>
              <MenuItem value="NCAABB">NCAABB</MenuItem>
              <MenuItem value="WNBA">WNBA</MenuItem>
              <MenuItem value="NFLPRE">NFLPRE</MenuItem>
              <MenuItem value="ATP">ATP</MenuItem>
              <MenuItem value="CFL">CFL</MenuItem>
              <MenuItem value="UFC">UFC</MenuItem>
              <MenuItem value="UFCW">UFCW</MenuItem>
            </Select>
          </div>
        </Sidebar>

        <Content>
          {isLoading ? (
            <LoaderCont>
              <CircularProgress />
            </LoaderCont>
          ) : (
            <ProductList>
              {martItems.length > 0 ? (
                <>
                  {martItems.map((item) => (
                    <ProductCard data={item} />
                  ))}
                </>
              ) : (
                <p>
                  No item to show
                </p>
              )}
            </ProductList>
          )}
        </Content>
      </MainBody>
    </article>
  );
};

export default SellerProfilePage;
