import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTodaysQueries } from "../../Apis/TodaysQueryApi";
import GeneralQueryCard from "../../Component/GeneralQueryCard";
import Navbar from "../../Component/Navbar";
import TodaysQueryCard from "../../Component/TodaysQueryCard";
import {
    Content,
    Heading,
    LoaderCont,
    MainBody,
    ProductList,
} from "./TodaysQueriesPage.styles";

const TodaysQueriesPage = () => {
    const { sportType } = useParams();
    const [martItems, setMartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        setIsLoading(true);
        const data = await getTodaysQueries(sportType);
        console.log(data);
        if (data) {
            setMartItems(data);
        }
        setIsLoading(false);
    };

    const fetchDataBg = async () => {
        const data = await getTodaysQueries(sportType);
        if (data) {
            setMartItems(data);
        }
    };

    useEffect(() => {
        fetchData();
        let interval = setInterval(() => {
            fetchDataBg();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <article>
            <Navbar />

            <MainBody>
                <Content>
                    <Heading>Todays queries of {sportType}</Heading>
                    {isLoading ? (
                        <LoaderCont>
                            <CircularProgress />
                        </LoaderCont>
                    ) : (
                        <ProductList>
                            {martItems.length > 0 ? (
                                <>
                                    {martItems.map((item) => (
                                        <TodaysQueryCard
                                            data={item}
                                            key={item.id}
                                            fetchData={fetchData}
                                        />
                                    ))}
                                </>
                            ) : (
                                <p>No item to show</p>
                            )}
                        </ProductList>
                    )}
                </Content>
            </MainBody>
        </article>
    );
};

export default TodaysQueriesPage;
