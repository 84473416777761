import axios from "axios";
import { URL } from "../Urls";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

export const getMartSellers = async () => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.get(URL + `mart-users/`, config);
    if(res.status === 200) {
      return res.data;
    }
  } catch(error) {
    console.error(error)
  }
  return false;
};

export const getMartItems = async (query, maxPrice, sportType, userId = "") => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.get(URL + `mart/${userId ? `${userId}/` : ''}?q=${query || ''}&maxPrice=${maxPrice || ''}&sportType=${sportType || ''}`, config);
    if(res.status === 200) {
      return res.data;
    }
  } catch(error) {
    console.error(error)
  }
  return false;
};

export const getMartItem = async (martItemId) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.get(URL + `mart/details/${martItemId}/`, config);
    if(res.status === 200) {
      return res.data;
    }
  } catch(error) {
    console.error(error)
  }
  return false;
};

export const postMartItem = async (data) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.post(URL + "mart/", data, config);
    if(res.status === 201) {
      return res.data
    }
  } catch(error) {
    console.error(error)
  }
  return false
};

export const deleteMartItem = async (queryId) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.delete(URL + "mart/remove/" + queryId + "/", config);
    if(res.status === 204) {
      return true
    }
  } catch(error) {
    console.error(error)
  }
  return false
};

export const getSellerMartItems = async (sellerId, query, maxPrice, sportType) => {
  console.log(sellerId, query, maxPrice, sportType)
  try {
    const res = await axios.get(URL + `mart/${sellerId ? `${sellerId}/` : ''}?q=${query || ''}&maxPrice=${maxPrice || ''}&sportType=${sportType || ''}`);
    if(res.status === 200) {
      return res.data
    }
  } catch(error) {
    console.error(error)
  }
  return false
};


export const getMartItemsCollection = async (collectionId) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.get(URL + `api/mart_item/${collectionId}/`, config);
    if(res.status === 200) {
      return res.data
    }
  } catch(error) {
    console.error(error)
  }
  return false
};


export const deleteMartItemsCollection = async (collectionId) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.delete(URL + `api/mart_item/${collectionId}/`, config);
    if(res.status === 204) {
      return true
    }
  } catch(error) {
    console.error(error)
  }
  return false
};

export const getMartItemsCollections = async (userId = null) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.get(URL + `api/mart_item/${userId ? `${userId}/user/` : ''}`, config);
    console.log(res.data)
    if(res.status === 200) {
      return res.data
    }
  } catch(error) {
    console.error(error)
  }
  return false
};

export const postMartItemsCollection = async (data) => {
  const config = {
    headers: {
      Authorization: cookies.get('jwt'),
    }
  }
  try {
    const res = await axios.post(URL + "api/mart_item/", data, config);
    if(res.status === 201) {
      return res.data
    }
  } catch(error) {
    console.error(error)
  }
  return false
};