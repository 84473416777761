import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link as LinkDOM, useHistory } from "react-router-dom";
import Cookies from "universal-cookie/es6";
import { getUserData } from "../Apis/UserApi";
import "../CSS/Login.css";
import { loginAction } from "../redux/MyActions";
import { URL } from "../Urls";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //backgroundColor: 'red',
    padding: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#bf0000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const [open, setOpen] = useState(false);

  const [alertState, setState] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = alertState;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const history = useHistory();

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorEmail("");
    setErrorPassword("");
    let flag = true;
    if (email === "") {
      setErrorEmail("Email is required");
      flag = false;
    }
    if (password === "") {
      setErrorPassword("Password is required");
      flag = false;
    }
    if (flag) {
      axios({
        method: "post",
        //url: "https://sdql-testapp.herokuapp.com/login/",
        url: URL + "login/",
        data: {
          email: email,
          password: password,
        },
        withCredentials: true,
      }).then(async (res) => {
        if (res.data.message === "Logged In") {
          const cookies = new Cookies();
          cookies.set("jwt", res.data.jwtToken, {
            path: "/",
            expires: new Date(Date.now() + 31536000000),
          });
          // props.doLogin();
          const userData = await getUserData();
          console.log(userData)
          if (userData) {
            props.doLogin(userData);
            history.push("/");
          }
        } else if (res.data.message === "Not Found") {
          setError("User Not Found");
          setOpen(true);
        } else {
          setError("Password is Incorrect");
          setOpen(true);
        }
      });
    }
  };

  return (
    <div>
      {props.isAuthenticated && history.replace("/")}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign In
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={errorEmail}
                helperText={errorEmail}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={errorPassword}
                helperText={errorPassword}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleLogin(e)}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item>
                  <LinkDOM to="/signup">Don't have an account? Sign Up</LinkDOM>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: () => dispatch(loginAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
