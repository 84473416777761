import { TextField, Typography } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

export const MainBody = styled("div")`
  display: flex;
  width: 100%;

  max-width: 1500px;
  min-height: calc(100vh - 260px);
  margin: auto;
  margin-top: 150px;
  margin-bottom: 50px;
  padding: 0px 20px;
`;

export const Content = styled("div")`
  flex: 1 0 100%;
`;

export const UserList = styled("ul")`
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: center; */

  /* margin: 50px; */
  /* gap: 25px; */
  padding: 0px 20px;
  /* @media only screen and (max-width: 600px) {
    justify-content: center;
  } */
`;

export const LoaderCont = styled("div")`
  display: grid;
  place-items: center;
  margin-top: 150px;
`;

export const Heading = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;
`;

export const FieldLabel = styled('label')`
  margin-bottom: 10px;
`;

export const QueryField = styled(TextField)`
  margin-bottom: 20px;
`;

export const Footer = styled('footer')`
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 16px;
`;

export const ListImg = styled('img')`
  width: 30px;
  height: 30px;

  border-radius: 50%;

  margin-left: 10px;
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
`;