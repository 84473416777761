const docs = {
    "Shortcuts": [
        {name: "A - away - Ex: Away team favored by less than a TD and the total is over 45 60", url: "A and line<-7 and total>45"},
        {name: "H - home - Ex: Home dogs of a home dog win that went over the total and the total is over 43", url: "HD and p:HDWO and total>43"},
        {name: "F - favorite - Ex: Home favorites after game 5 who have yet to win a game", url: "wins=0 and HF and game number>5"},
        {name: "D - dog - Ex: Home teams that beat the opp 4-straight times", url: "H and and line>7 and and P:W and PP:W and PPP:W and PPPP:W "},
        {name: "O - over - Ex: Dogs of 18+ off 2-straight overs where the total is the lowest of the last 3", url: "p:O and p2:O and total<p:total and total<p2:total and line>17"},
        {name: "U - under - Ex: home teams off a home under loss as the favorite and the total is over 60", url: "p:HULF and p:total<50 and H"},
        {name: "W - win - Ex: Home favs laying under 3 points that won 5 in a row", url: "tS(W,N=5)=5 and H and line<-3"},
        {name: "L - loss - Ex: Away favs that lost 5 of last 7", url: "tS(L,N=7)=5 and AF"},
        //{name: "C - conference - Ex: Lions as home dogs in conference games", url: "team=Lions and HD and C"},
        //{name: "3DA - 3rd down attempts - Ex: Teams off a game with over 20 3rd down attempts and made 50%", url: "p:3DA>20 and p:3DM>10"},
        //{name: "3DF - 3rd down failures - Ex: Teams off a home win with over a dozen 3rd down failures and now over a TD road dog", url: "p:3DF>11 and p:W and p:H and A and line>7"},
        //{name: "3DM - 3rd downs made - Ex: Team won as home dog while making 3 more 3rd downs then their last opponent", url: "p:3DM>po:3DM+5 and p:HDW"},
        //{name: "3DP - 3rd down percentage - Ex: Last game, team lost and made over 50% of 3rd down tries while opp was under 30%", url: "p:3DP>50 and po:3DP<30 and p:L"},
        //{name: "4DA - 4th down attempts - Ex: Team made over 2 4th down tries and won at a home dog", url: "p:4DA>2 and p:HDW"},
        //{name: "4DF - 4th down failures - Ex: Show season totals of 4th down attempts, made, and failures", url: "R(team),tS(4DA),tS(4DM),tS(4DF)@team and season=2022"},
        //{name: "4DM - 4th downs made - Ex: Teams off a win at home as a dog where both teams and 4 or more 4th down conversions", url: "p:4DM>3 and op:4DM>3 and HDW"},
        //{name: "4DP - 4th down percentage - Ex: Team off game where they tried over 2 4th downs and converted all", url: "p:4DA>2 and p:4DP=100"},
        {name: "ATSL - against the spread loss - Ex: This shows the one team in the database that failed to cover 10 straight games", url: "tS(ATSL,N=10)=10"},
        {name: "ATSW - against the spread win - Ex: This shows the one team in the database that covered 12 straight games", url: "tS(ATSW,N=7)=7"},
        //{name: "ATSWP - against the spread win percentage - Ex: Home doge getting more than a TD with an ATS win precentage over 60 and who beat the opponent in the last meeting", url: "ATSWP>60 and week>10 and line>7 and H and P:W"},
        {name: "AWP - away win percentage - Ex: Lion's away winning percentage", url: "date,team,o:team,site,tS(AW)*1,tS(AL)*1,AWP@team=Lions and season=2022"},
        //{name: "BG - bowl game - Ex: How the loser of a bowl game does in a rematch the following season", url: "BG and P:season=season-1 and P:L"},
        {name: "BYE - Undefeated away dogs off a bye - Ex: ", url: "p:BYE and AD and WP=100"},
        {name: "COMP - completions - Ex: Redblack's passing numbers", url: "passes,COMP,PY@team=Redblack's and season=2022"},
        {name: "CP - completion percentage - Ex: Teams off an away win as a dog with an 80% completion percentage going into home game.", url: "p:CP>80 and p:ADW and H"},
        {name: "division (DIV) - Ex: Roughriders record against their own devision", url: "team=Roughriders and division=o:division"},
        //{name: "DTD - defensive touchdowns - Ex: Teams off a game with at at least one defensive and kick return TD going into a divisional game as favorites", url: "p:DTD!=p:NOTD and p:DTD>0 and DIV and F"},
        {name: "FD - first downs - Ex: Teams average 1st downs per game", url: "tA(FD)@team season=2022"},
        //{name: "FDP - first downs percentage - Ex: Teams of a Sunday Night game where the made a 1st down on 45% of the plays", url: "p:FDP>45 and p:SNF"},
        // {name: "GTGA - Ex: ", url: ""},
        // {name: "GTGM - Ex: ", url: ""},
        {name: "HWP - home win percentage - Ex: After game 7, favorites without a home win laying more than a FG", url: "HWP=0 and H and line<-3 and game number>7"},
        {name: "INC - incomplete pass - Ex: Teams of an away favorite win that had more penalties than incomplete passes and are now home getting 7 or more", url: "p:PEN>p:INC and p:WF and H and line>7"},
        {name: "INT - interceptions - Ex: Teams off a game with over 5 interceptions", url: "po:interceptions>5"},
        {name: "M1 - margin after quarter 1 - Ex: Teams off a game where they were ahead by 14 or more after the 1st quarter and lost on the road and away again", url: "p:M1>=14 and p:AL and A"},
        {name: "M2 - margin at the half - Ex: Teams down by a TD or more at the half last game and came back for a road win and now a home dog", url: "p:M2<=-7 and p:AFW and HD"},
        {name: "M3 - margin after quarter 3 - Ex: Teams off an away win where they came back being down a TD or more entering the 4th quarter and now are home dogs", url: "p:M3<=-7 and p:AW and HD"},
        //{name: "MNF - Monday Night Football - Ex: Teams from the Pacific time zone playing away against an Eastern time zone team on Monday Night", url: "A and time zone=P and o:time zone=E"},
        {name: "NB - team not off a bye - Ex: Teams not off a bye against a team off a bye and Thursdays", url: "p:NB and op:BYE and TNF"},
        //{name: "NDIV - non-division game - Ex: Away team in an FCS vs FBS game with a next game at home against its own division ", url: "NDIV and A and n:C and n:H"},
        //{name: "NOTD - non-offensive touchdowns - Ex: Teams off a game with 3 or more defensive TDs", url: "p:NOTD>2"},
        //{name: "OFPL - offensive plays - Ex: Last meeting, teams that had 30 or more offensive plays and lost", url: "P:OFPL>Po:OFPL+30 and P:L"},
        {name: "OT - overtime - Ex: Last meeting was a home OT loss that went under the total", url: "P:OT and P:UL and P:H "},
        {name: "P1 - team's points in 1st quarter - Ex: Teams off a game where 1Q was the highest scoring quarter and the team rushed for over 150 yards in a loss that went under the total and now are home dogs", url: "p:P1>p:P2 and p:P1>p:P2 and p:P1>p:P3 and p:P1>p:P4 and p:RY>150 and p:UL and HD"},
        {name: "P2 - team's points in 2nd quarter - Ex: Team off a game with no 1st half point and then went on to win a game that went over the total", url: "p:P1+P2=0 and p:OW"},
        {name: "P3 - team's points in 3rd quarter - Ex: Teams off a game with no 2nd half points in a game that they won and was over the total", url: "p:P3+p:P4=0 and p:OW"},
        {name: "P4 - team's points in 4th quarter - Ex: Home dogs game where the outscored the opp by at least 2 TD in the 4th Q and had over 300 passing yards", url: "p:P4>po:P4+14 and p:PY>300 and HD"},
        {name: "PEN - number of penalties - Ex: Teams off a win with no penalties and now home again", url: "p:PEN=0 and p:HW and H"},
        //{name: "PENFD - penalty first downs - Ex: Teams off an away game where the got over 5 first down via penalty and lost", url: "p:PENFD>5 and p:AL"},
        {name: "PENY - penalty yards - Ex: Teams that average over 100 penalty yards per game now playing an away conference game", url: "tA(PENY)>100 and C and A"},
        //{name: "PFD - passing first downs - Ex: Teams that have 75 more passing first downs than they allowed after week 10", url: "tS(PFD)>oS(PFD)+75 and week>10"},
        //{name: "PO - playoffs - Ex: Playoff teams off a playoff win as the favorite where they did not cover the spread and are favorites again", url: "p:PO and PO and p:F and p:ATSL and F"},
        {name: "PY - passing yards - Ex: Away favs after 4-straight 300+ passing yard games", url: "p:PY>300 and p2:PY>300 and p3:PY>300 and p4:PY>300 and AF"},
        {name: "RY - rushing yards - Ex: Away favs that lost 2 straight games and rushed for over 200 in both", url: "p:RY>200 and p2:RY>200 and tS(W,N=2)=0 and AF"},
        {name: "REG - regular season game - Ex: Regular season matchup of a team in the top 10 off a loss to a top 10 team as an away fav of a TD or less", url: "REG and rank>=10 and o:rank=None and A and line>-7.5 and p:L and po:rank>=10"},
        //{name: "RFD - rushing first down - Ex: Home favorites that are averaging over 10 rushing fisrt downs per game", url: "tA(RFD)>10 and HF"},
        //{name: "RTD - rushing touchdowns - Ex: Away favorites that have 20 more rushing TDs than passing TD after game 10", url: "tS(RTD)>tS(PTD)+20 and game number>10 and AF"},
        {name: "RY - rushing yards - Ex: The game with the most rushing yards in the database", url: "RY>650"},
        // {name: "RZA - Ex: ", url: ""},
        // {name: "RZF - Ex: ", url: ""},
        // {name: "RZM - Ex: ", url: ""},
        {name: "S1 - points at end of 1st quarter - Ex: Teams off a away loss as the fav with no points in the 1st quarter and over 14 points in the 2nd quarter", url: "p:S1=0 and p:S2>14 and p:AFL"},
        {name: "S2 - points at the half - Ex: Away teams off a loss where neither team scored in the 1st half", url: "p:S2=0 and po:S2=0 and p:L and A and season>2012"},
        {name: "S3 - points at end of 3rd quarter - Ex: Teams off a home win where they were scoreless thru 3 quarters", url: "p:S3=0 and p:HW"},
        //{name: "SBL - Super Bowl loser - Ex: Super Bowl loser in week 1 and away", url: "week=1 and SBL and A"},
        //{name: "SBW - Super Bowl winner - Ex: Super Bowl winners in week 1 and H", url: "week=1 and SBW and H"},
        {name: "SIQ - scored in quarters - Ex: Teams of an OT win where both teams scored in 4 quarters", url: "p:SIQ=4 and po:SIQ=4 and p:OT and p:W"},
        {name: "TNF - Sunday Night Football - Ex: Home team under .500 against a team that's over .500 on Thursday night ", url: "TNF and WP<50 and o:WP>50 and H"},
        // {name: "STDRAPG - Ex: ", url: ""},
    ],
    "Parameters": [
        //{name: "ap rank - Ex. ",url: ""},
        {name: "ats margin - Ex. Favs of 8 or less that have not covered in the last 4 in games playing a team with an under .450 win % before week 10",url: "week<10 and line>-8.5 and F and Maximum(ats margin@team and season,N=4)<0 and o:WP<45"},
        {name: "ats streak - Ex. Away teams ranked between 16 and 25 that failed to cover in their last 3",url: "ats streak<=-3 and rank<=15 and A"},
        //{name: "away site streak - Ex. ",url: "tS(away site streak)@team and conference in ['SEC','ACC','Big Ten'] and season=2022"},
        //{name: "coach - Ex. ",url: ""},
        {name: "completions - Ex. Home dogs of an away win as favorites with under 10 completions",url: "p:completions<10 and p:AFW and HD"},
        {name: "conference - Ex. How the SEC has fared against the Big 10",url: "conference=SEC and o:conference=Big Ten"},
        {name: "data date - Ex: The last date the data was updated for week 1", url: "team,o:team,data date,data time@season=2022 and week=1"},
        {name: "data time - Ex: The last date the data was updated for week 1", url: "team,o:team,data date,data time@season=2022 and week=1"},
        //{name: "dataset - Ex. ",url: ""},
        {name: "date - Ex. Today's Favorites",url: "date=today and F"},
        {name: "day - Ex. Thursday home favs of more than a TD when the total is over 50",url: "day=Thursday and H and line<-7 and total>50"},
        //{name: "division - Ex. ",url: ""},
        {name: "dpa (delta points allowed) - Ex: Favs off 2-straight game where they allowed 14 points over the opponents team total", url: "p:dpa>14 and pp:dpa>14 and F"},
        {name: "dps (delta points scored) - Ex: Teams who score 3 touchdowns over their team total and lost", url: "p:dps>21 and p:L"},
        //{name: "fcs rank - Ex. ",url: ""},
        {name: "first downs - Ex. Away dogs off a game with over 30 first downs",url: "p:first downs>30 and AD and season>2018"},
        //{name: "fourth downs attempted - Ex. ",url: ""},
        //{name: "fourth downs made - Ex. ",url: ""},
        {name: "full name - Ex. Team's acronym and full name",url: "R(team),R(full name)@team"},
        {name: "fumbles - Ex. Favs in a bowl game that average over to fumbles per game",url: "tA(fumbles)>2 and BG and F"},
        {name: "fumbles lost - Ex. Favs that averaged over 2 fumbles/game over their last 4",url: "tA(fumbles lost,N=4)>2 and F"},
        {name: "game number - Ex. Winless home team after game 9 who are getting more than 3 TDs",url: "tS(W)=0 and game number>9 and H and line>14"},
        {name: "game type - Ex. Favs in the college playoffs",url: "game type=PO and F"},
        //{name: "home site streak - Ex. ",url: ""},
        //{name: "id - Ex. ",url: ""},
        {name: "interceptions - Ex. SEC team's interception totals",url: "R(team),tA(interceptions)@team and conference=SEC and season=2022"},
        //{name: "kicking extra points - Ex. ",url: ""},
        {name: "losses - Ex. Michigan's record",url: "wins,losses,o:team,points,o:points@team=MICH and season=2022"},
        {name: "margin - Ex. Bama's record and margins of victory",url: "wins,losses,o:team,points,o:points,margin,tA(margin)@team=ALA and season=2022"},
        {name: "margin after the first - Ex. All teams difference in score after the 1st quarter",url: "team,tA(margin after the first)@team and season=2022"},
        {name: "margin after the third - Ex. Teams up by more than 10 in the last meeting and lost and home",url: "P:margin after the third>10 and P:HL "},
        {name: "margin at the half - Ex. Teams off a game they were tied at halftime and end up winning by more than 3 TDs",url: "p:margin at the half=0 and p:margin>21"},
        //{name: "matchup losses - Ex. ",url: ""},
        //{name: "matchup wins - Ex. ",url: ""},
        {name: "month - Ex. September games between the SEC and Big Ten",url: "H and month=9 and conference=SEC and o:conference=Big Ten"},
        //{name: "net passing yards - Ex. ",url: ""},
        //{name: "open line - Ex. ",url: ""},
        //{name: "open odds - Ex. ",url: ""},
        //{name: "open total - Ex. ",url: ""},
        //{name: "open total odds - Ex. ",url: ""},
        //{name: "opponents - Ex. ",url: ""},
        {name: "ou margin - Ex. How team's do in week 4 when they're averaging under 21 points against the total for the season",url: "tA(ou margin)<-21 and week=4"},
        {name: "ou streak - Ex. Teams with over 6-straign unders and the total is lower than the previous",url: "ou streak<-6 and total<p:total"},
        {name: "overtime - Ex. A teams off a home dog overtime win",url: "p:overtime=1 and p:HDW and A"},
        {name: "passes - Ex. Home teams off an away win that went over the total and threw fewer than 10 passes",url: "p:passes<10 and p:AWO and H"},
        //{name: "passing touchdowns - Ex. ",url: ""},
        {name: "passing yards - Ex. Big Ten team's passing yards numbers",url: "R(team),tA(passing yards)@team and season=2022 and conference=Big Ten"},
        {name: "penalties - Ex. Dogs who average 7 more penalties on the season than their opponent",url: "tA(penalties)>oA(penalties)+7 and D"},
        {name: "penalty yards - Ex. Teams off a game with no point and no penalties",url: "p:penalty yards=0 and po:points=0"},
        //{name: "playoffs - Ex. ",url: ""},
        //{name: "plays - Ex. ",url: ""},
        {name: "points - Ex. Team off a game where they score over 50 and lost",url: "p:points>50 and p:L"},
        {name: "quarter scores - Ex. Quarter scores for the Lions",url: "date,team,o:team,quarter scores,o:quarter scores@season=2022 and team=Lions"},
        {name: "rank - Ex. How the higher ranked team does in week 1 games",url: "week=1 and rank<o:rank "},
        {name: "rank ap - Ex. Away dogs who are ranked higer in the coaches poll than the AP poll",url: "rank>rank ap+1 and AD"},
        //{name: "rank fcs - Ex. ",url: ""},
        {name: "rest - Ex. Home teams on a week's rest playing a team on over 3 weeks rest and the opp lost last game",url: "rest=6 and o:rest>21 and op:L and H"},
        //{name: "round - Ex. ",url: ""},
        {name: "rushes - Ex. Away favs off a game with 45 rushing attempts and playing a team that had under 50 rushing yards last game",url: "tA(rushes)>45 and opo:RY<50 and AF"},
        //{name: "rushing touchdowns - Ex. ",url: ""},
        {name: "rushing yards - Ex. Teams off a 500-rushing yard game",url: "p:rushing yards>500"},
        //{name: "sack yards - Ex. ",url: ""},
        //{name: "sacks - Ex. ",url: ""},
        //{name: "safeties - Ex. ",url: ""},
        {name: "school - Ex. ACC team's and their school names",url: "R(team),R(full name),R(school)@team and conference=ACC and season=2022"},
        {name: "season - Ex. Blue Bombers' record by season",url: "R(season),tS(W),tS(L)@team=Blue Bombers and season"},
        {name: "seed - Ex. Seeds in the college playoffs",url: "R(seed) as 'Seed',R(team) as 'Team',R(season) as 'Season'@team and season and game type=PO "},
        {name: "site - Ex. Stamps as an away fav",url: "team=Stampeders and F and site=away and season>2005"},
        //{name: "site streak - Ex. ",url: ""},
        //{name: "special teams td - Ex. ",url: ""},
        //{name: "start time - Ex. ",url: "date,team,full name,o:team,o:full name,start time,week@season=2022 and week=2"},
        {name: "streak - Ex. Home dogs on a 5+ game run",url: "streak>5 and HD"},
        //{name: "streak date - Ex. ",url: ""},
        //{name: "surface - Ex. How San Diego State does on artifical turf",url: "surface=artificial and team=SDST"},
        {name: "team - Ex. Michigan this season",url: "team=Elks and season=2022"},
        //{name: "temperature - Ex. ",url: ""},
        {name: "third downs attempted - Ex. Home teams off a home game in which they attempted under 7 3rd down conversion and scored fewer than 7 points",url: "p:third downs attempted<7 and p:points<7 and p:H and H"},
        {name: "third downs made - Ex. Team off a home win in which they made over 20 1st downs",url: "p:third downs made>20 and p:HW"},
        //{name: "time of possession - Ex. ",url: ""},
        //{name: "time zone - Ex. ",url: ""},
        {name: "turnove margin - Ex. Team's the game after beating Argonauts and winning the turnover battle ",url: "p:turnover margin>0 and po:team=Argonauts and p:W"},
        {name: "turnovers - Ex. Team's that have over 4 more takeaways than their last opp as and away dog",url: "P:turnover margin<-4 and P:AD"},
        //{name: "two point conversions - Ex. ",url: ""},
        {name: "week - Ex. Week 2 schedule",url: "week=2 and season=2022 and H"},
        //{name: "wind - Ex. ",url: ""},
        {name: "wins - Ex. How home dogs do when they have at 2 more wins than their opponent",url: "wins>o:wins+2 and HD and season>2005"},
    ],
    "Prefixes": [
        {name: "op: (opp's previous game)", url: "p:points<=10 and op:points<=10"},
        {name: "p: (team's previous game)", url: "p:L and p:points>40"},
        {name: "t: - team (if omitted, assumed)", url: "t:team=Redblacks and t:site=home"},
        {name: "o: - opponent", url: "team=Argonauts and o:team=Alouettes and o:line<-7"},
        {name: "p: - previous game", url: "p:points>=30 and op:points>=30"},
        {name: "op: - opponent's previous game", url: "p:points>=30 and op:points>=30"},
        {name: "P: - previous game against opponent", url: "P:HDW and H"},
        {name: "n: - next game", url: "n:date>today+1"},
        {name: "on: - opponent's next game", url: "op:A and H and on:A"},
        {name: "N: - next game against opponent", url: "H and N:A"},
    ]
}

export {docs};