import {
  Box,
  Button,
  Modal,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import React from "react";
import { updateSavedQuery } from "../../../Apis/QueryApi";

const EditQueryModal = ({
    fetchData,
    open,
    setOpen,
    currentEditQuery,
    setCurrentEditQuery,
}) => {
    const [loading, setLoading] = React.useState(false);

    const handleUpdate = async (event, queryId) => {
        event.preventDefault();
        setLoading(true);
        if (event.target.query.value) {
        const data = {
            query: event.target.query.value,
            description: event.target.description.value,
        };
        const res = await updateSavedQuery(data, queryId);
        if (res) {
            setOpen(false);
            fetchData();
        }
        }
        setLoading(false);
    };


    const handleEditClose = () => {
        setCurrentEditQuery({});
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={()=>handleEditClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "800px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                }}
            >
                <h3 className="fs-3">Edit SDQL Form</h3>
                <form onSubmit={(e) => handleUpdate(e, currentEditQuery.id)}>
                <TextField
                    style={{ width: "100%", marginBottom: "20px" }}
                    type="text"
                    name="query"
                    label="SDQL"
                    variant="standard"
                    value={currentEditQuery.content}
                    onChange={(e) =>
                        setCurrentEditQuery({
                            ...currentEditQuery,
                            content: e.target.value,
                        })
                    }
                    required
                />

                <TextareaAutosize
                    aria-label="Description"
                    minRows={3}
                    placeholder="Description"
                    name="description"
                    value={currentEditQuery.description}
                    onChange={(e) =>
                    setCurrentEditQuery({
                        ...currentEditQuery,
                        description: e.target.value,
                    })
                    }
                    style={{
                    width: "100%",
                    marginBottom: "20px",
                    borderRadius: "10px",
                    borderColor: "rgba(0, 0, 0, 0.42)",
                    padding: "10px",
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                >
                    Update
                </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default EditQueryModal;
