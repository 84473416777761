import {
  Box,
  Button,
  Modal,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from "react";
import { updateTodaysQuery } from "../../../Apis/TodaysQueryApi";

const EditTodaysQueryModal = ({ fetchData, open, setOpen, data }) => {
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState(data.content);
  const [description, setDescription] = React.useState(data.description);
  const [order, setOrder] = React.useState(data.order);

  const [expireAtvalue, setExpireAtValue] = React.useState(new Date(data.expire_at * 1000));

  const handleDateChange = (value) => {
    setExpireAtValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (query) {
      const formData = {
        query: query,
        description: description,
        sport_type: data.sport_type,
        order: order,
        expire_at: expireAtvalue,
      };
      try {
        const res = await updateTodaysQuery(formData, data.id);
        if (res && res?.status === 201) {
          setOpen(false);
          fetchData();
          event.target.query.value = "";
        }
      } catch (error) {
        console.error(error.response);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3 className="fs-3">Edit General SDQL Form</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            type="text"
            name="query"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            label="SDQL"
            variant="standard"
            required
          />

          <TextareaAutosize
            aria-label="Description"
            minRows={3}
            placeholder="Description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              width: "100%",
              marginBottom: "20px",
              borderRadius: "10px",
              borderColor: "rgba(0, 0, 0, 0.42)",
              padding: "10px",
            }}
          />

          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            type="number"
            name="order"
            value={order}
            onChange={(e) => setOrder(e.target.value)}
            label="Order"
            variant="standard"
            required
          />

          <div
            style={{
              width: "100%",
              marginBottom: "20px",
              borderRadius: "10px",
              borderColor: "rgba(0, 0, 0, 0.42)",
              padding: "10px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Expire At"
                name="expire_at"
                value={expireAtvalue}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default EditTodaysQueryModal;
