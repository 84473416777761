import styled from "styled-components";

export const Body = styled.div`
    position: relative;
    background-color: #fff;
    box-shadow: -1px 2px 15px -4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    /* min-height: 300px; */
    min-width: 200px;
    max-width: 250px;
    flex: 0 0 25%;
    padding: 15px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const Heading = styled.h3`
    font-size: 1.5rem;

    & span {
        color: red;
    }

    & small {
        color: #8f8f8f;
    }
`;

export const Description = styled.p`
    font-size: 0.9rem;
    color: #8f8f8f;
    word-wrap: pre-wrap;
    height: 100px;
    overflow-y: auto;
    padding-right: 5px;

    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
    }

    ::-webkit-scrollbar-thumb {
        background: red;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: red;
    }
`;

export const UseButton = styled.button`
    background-color: red;
    color: #fff;
    max-width: 150px;
    width: 100%;
    margin: 0px auto;
    border-radius: 20px;
`;

export const MainSection = styled.div`
    border-bottom: 1px solid #ececec;
`;

export const SellerInfoSection = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PointsText = styled.p`
    font-size: 0.9rem;

    & span {
        font-size: 1rem;
        color: red;
    }

    & small {
        color: #8f8f8f;
    }
`;


export const ConfigButtonsCont = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  `;

export const IconCont = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: red;

  & img {
    width: 15px;
    height: 15px;
  }
`;
