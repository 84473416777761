import { TextField, Typography } from "@material-ui/core";
import styled from "styled-components";

export const MainBody = styled("div")`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  max-width: 1500px;
  margin: auto;
  margin-top: 150px;
  margin-bottom: 50px;
  padding: 0px 20px;
  `;

export const Sidebar = styled("aside")`
  flex: 0 1 30%;
  max-width: 300px;
  border-radius: 25px;
  padding: 20px;
  min-height: calc(100vh - 210px);
  box-shadow: 0px 2px 15px -3px rgba(0,0,0,.2);
`;

export const Content = styled("div")`
  flex: 1 0 70%;
`;

export const ProductList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */

  /* margin: 50px; */
  gap: 25px;
  padding: 0px 20px;
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const LoaderCont = styled("div")`
  display: grid;
  place-items: center;
  margin-top: 150px;
`;

export const Heading = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;
`;

export const FieldLabel = styled('label')`
  margin-bottom: 10px;
`;

export const QueryField = styled(TextField)`
  margin-bottom: 20px;
`;

export const Footer = styled('footer')`
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 16px;
`;


export const Header = styled("header")`
  flex: 1 0 100%;
  /* max-width: 00px; */
  border-radius: 25px;
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

export const UserBannerCont = styled("div")`
  flex: 0 1 65%;
  /* height: 65%; */
  background-color: red;
`;

export const UserInfoCont = styled("div")`
  flex: 1 0 35%;
  max-height: calc(100% - 50px);
  padding: 10px 50px;
`;

export const UserAvatarCont = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;
  max-height: 80px;
`;

export const UserAvatar = styled("img")`
  position: relative;
  top: -30px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #000;
`;

export const UserName = styled("p")`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0px;
`;

export const MutedText = styled("p")`
  font-size: 0.9rem;
  color: #999;
  margin: 0px;
`;

export const BioSection = styled("div")`
  width: 100%;
  height: 0px;
  transform: scaleY(0);
  overflow-y: hidden;
  transition: all 0.5s ease;
  padding-top: 10px;
  padding-bottom: 0px;
  transform-origin: top;
  white-space: pre-wrap;

  &.show {
    height: fit-content;
    transform: scaleY(1);
    padding-bottom: 50px;
  }
`;

export const CreateCollectionButton = styled.button`
  background-color: red;
  color: #fff;
  /* max-width: 150px; */
  width: max-content;
  margin: 10px 0px;
  border-radius: 20px;
  border-color: transparent;
  outline: none;
  padding: 5px 10px;
`;