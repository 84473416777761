import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../../Component/Navbar';
import { PREVIOUS_PAYMENT_URL } from '../../constants/localStorage.constants';
import { Heading, MainCont } from './PaymentSuccessfulPage.styles';

const PaymentSuccessfulPage = () => {
  const history = useHistory();

  useEffect(() => {
    const previousPaymentUrl = localStorage.getItem(PREVIOUS_PAYMENT_URL);
    if (!previousPaymentUrl) {
      history.replace('/')
    } else {
      localStorage.setItem(PREVIOUS_PAYMENT_URL, "")
    }
  }, [])

  return (
    <article>
      <Navbar />

      <MainCont>
        <Heading>
          Payment was successful!
        </Heading>
      </MainCont>
    </article>
  )
}

export default PaymentSuccessfulPage