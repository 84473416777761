import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { WindowSharp } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { DualRing } from "react-awesome-spinners";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getGeneralUseQueries } from "../../Apis/GeneralUseQueryApi";
import { postSavedQuery } from "../../Apis/QueryApi";
import { getTodaysQueries } from "../../Apis/TodaysQueryApi";
import CreateGeneralQueryModal from "../../Component/Modals/CreateGeneralQueryModal";
import CreateTodaysQueryModal from "../../Component/Modals/CreateTodaysQueryModal";
import Navbar from "../../Component/Navbar";
import SportDocumentation from "../../Component/SportDocumentation";
import "../../CSS/Subpage.css";
import { visitAction } from "../../redux/MyActions";
import { URL } from "../../Urls";

let interval;

const ProcessQueryPage = (props) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [queryText, setQueryText] = useState("");
  const [openGeneralUseQueryModal, setOpenGeneralUseQueryModal] =
    useState(false);
  const [openTodaysQueryModal, setOpenTodaysQueryModal] = useState(false);
  const [htmlStr, setHtmlStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [generalQueries, setGeneralQueries] = useState([]);
  const [todaysQueries, setTodaysQueries] = useState([]);

  const { sportType } = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("q");
  const queryComp = searchParams.get("qc");

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const [alertState, setState] = useState({
    vertical: "bottom",
    horizontal: "center",
  });

  const { vertical, horizontal } = alertState;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const history = useHistory();

  useEffect(() => {
    if (query) {
      setQueryText(query);
      submitQuery(query, true);
    }
    if (queryComp) {
      setQueryText(queryComp);
      submitQuery(queryComp, false);
    }
  }, [query]);

  useEffect(() => {
    setHtmlStr("");
    setGeneralQueries([]);
    // source.cancel('SportType changed.');
  }, [sportType]);

  const days = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  const submitQuery = async (query, flag) => {
    //console.time('doSomething')
    setHtmlStr("");
    setLoading(true);
    let interID = setInterval(() => {
      let value = document.getElementById("myTable1");
      if (value) {
        setLoading(false);
        handleCheck(1);
        clearInterval(interID);
      }
      value = document.getElementById("myTable2");
      if (value) {
        setLoading(false);
        handleCheck(2);
        let showMore = document.getElementById("showMore");
        if (showMore) {
          showMore.onclick = handleShowMore.bind(this);
        }
        clearInterval(interID);
      }
      value = document.getElementById("myTable3");
      if (value) {
        setLoading(false);
        handleCheck(3);
        clearInterval(interID);
      }
      value = document.getElementById("invalid-query");
      if (value) {
        setLoading(false);
        //handleCheck(4)
        clearInterval(interID);
      }
    }, 1000);
    axios({
      method: "post",
      //url: "https://sdql-testapp.herokuapp.com/query_result/",
      cancelToken: source.token,
      url: URL + "query_result/",
      data: {
        query: query,
        sport: sportType,
        showComplete: flag,
        jwtCookie: props.jwtCookie,
      },
      //withCredentials: true,
    }).then((res) => {
      setLoading(false);
      if (res.data.error) {
        setError(res.data.errorMessage);
        setOpen(true);
      } else {
        setHtmlStr(res.data.htmlString);
      }
    });
    //.then(res => console.log(res))
  };

  const queryIsValid = async (query, flag) => {
    try {
      const res = await axios({
        method: "post",
        cancelToken: source.token,
        url: URL + "query_result/",
        data: {
          query: query,
          sport: sportType,
          showComplete: flag,
          jwtCookie: props.jwtCookie,
        },
      });
      if (res.data.error) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const handleCheck = (tableNo) => {
    let table = document.getElementById("myTable" + tableNo);
    let value = table.getElementsByTagName("th");
    if (tableNo === 1) {
      for (let i = 0; i < value.length; i++) {
        value[i].onclick = sortTable1.bind(this, i);
      }
    } else if (tableNo === 2) {
      for (let i = 0; i < value.length; i++) {
        value[i].onclick = sortTable2.bind(this, i);
      }
    } else {
      for (let i = 0; i < value.length; i++) {
        value[i].onclick = sortTable3.bind(this, i);
      }
    }
  };

  function sortTable1(n, e) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      v1,
      v2,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("myTable1");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        v1 = rows[i].getElementsByTagName("td")[n].innerHTML;
        v2 = rows[i + 1].getElementsByTagName("td")[n].innerHTML;
        let t1 = parseFloat(v1);
        let t2 = parseFloat(v2);
        if (isNaN(t1)) {
          x = v1;
          y = v2;
        } else {
          x = t1;
          y = t2;
        }
        if (dir === "asc") {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  function sortTable2(n, e) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      v1,
      v2,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("myTable2");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        v1 = rows[i].getElementsByTagName("td")[n].innerHTML;
        v2 = rows[i + 1].getElementsByTagName("td")[n].innerHTML;
        if (n === 0) {
          x = new Date(v1);
          y = new Date(v2);
        } else if (n === 2) {
          x = days[v1];
          y = days[v2];
        } else {
          if (n === 1) {
            v1 = v1.replace(":", ".")
            v2 = v2.replace(":", ".")
          }
          let t1 = parseFloat(v1);
          let t2 = parseFloat(v2);
          if (t1) {
            x = t1;
            y = t2;
          } else {
            x = v1;
            y = v2;
          }
        }
        if (dir === "asc") {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  function sortTable3(n, e) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      v1,
      v2,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("myTable3");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        v1 = rows[i].getElementsByTagName("td")[n].innerHTML;
        v2 = rows[i + 1].getElementsByTagName("td")[n].innerHTML;
        let t1 = parseFloat(v1);
        let t2 = parseFloat(v2);
        if (isNaN(t1)) {
          x = v1;
          y = v2;
        } else {
          x = t1;
          y = t2;
        }
        if (dir === "asc") {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  const handleEnter = (event) => {
    if (event.key === "Enter" && queryText) {
      event.preventDefault();
      history.push(`/${sportType}?q=` + encodeURIComponent(queryText));
    }
  };

  const handleShowMore = () => {
    history.push(`/${sportType}?qc=` + encodeURIComponent(query));
  };

  if (props.firstVisit) {
    axios({
      method: "post",
      //url: "https://sdql-testapp.herokuapp.com/isAuthenticated/",
      url: URL + "isAuthenticated/",
      data: {
        jwtCookie: props.jwtCookie,
      },
    }).then((res) => {
      if (res.data.message === "OK") {
        props.doAuthenticate();
      } else {
        history.replace("/login");
      }
    });
  } else {
    if (!props.isAuthenticated) {
      history.replace("/login");
    }
  }

  const [saveLoading, setSaveLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState({
    ok: null,
    message: "",
  });

  const handleQuerySave = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    if (queryText) {
      const isValid = await queryIsValid(queryText, true);
      console.log(isValid);

      if (isValid) {
        const data = {
          query: queryText,
          sport_type: sportType,
        };
        try {
          const res = await postSavedQuery(data);
          console.log(res);
          if (res.status === 201) {
            setSaveStatus({
              ok: true,
              message: "Query saved successfully",
            });
          }
        } catch (error) {
          console.error(error.response);
          setSaveStatus({
            ok: false,
            message: error.response.data?.error,
          });
        }
      } else {
        setSaveStatus({
          ok: false,
          message: "Bad Query",
        });
      }
    }
    setSaveLoading(false);
  };

  const fetchGeneralQueries = async () => {
    const data = await getGeneralUseQueries(sportType);
    if (data) {
      setGeneralQueries(data);
    }
  };

  const fetchTodaysQueries = async () => {
    const data = await getTodaysQueries(sportType);
    if (data) {
      setTodaysQueries(data);
    }
  };

  useEffect(() => {
    fetchGeneralQueries();
    fetchTodaysQueries();

    // if (sportType === "MLBX" && !queryText) {
    //   setQueryText("season=2023 and team")
    //   history.push(
    //     `/${sportType}?q=` + encodeURIComponent("season=2023 and team")
    //   );
    // }

    if (interval) clearInterval(interval);

    interval = setInterval(() => {
      fetchGeneralQueries();
      fetchTodaysQueries();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [sportType]);

  return (
    <div>
      <Navbar />
      <div className="query">
        <div
          className="d-flex justify-content-center align-items-center w-100 mb-4"
          style={{ maxWidth: "1200px" }}
        >

          <TextField
            id="standard-textarea"
            label="Enter Query"
            placeholder="SDQL"
            multiline
            variant="standard"
            style={{ flex: "0 1 90%" }}
            onKeyPress={(e) => handleEnter(e)}
            value={queryText}
            onChange={(e) =>
              setQueryText((e.target.value && e.target.value) || "")
            }
          />
          <button
            type="button"
            className="btn btn-outline-danger mx-2"
            onClick={() => {
              if (queryText) {
                history.push(
                  `/${sportType}?q=` + encodeURIComponent(queryText)
                );
              }
            }}
          >
            SDQL
          </button>
          <button
            type="button"
            className={`btn mx-2 ${
              saveStatus.ok
                ? "btn-success"
                : saveStatus.ok === false
                ? "btn-danger bg-red"
                : "btn-outline-danger"
            }`}
            onClick={(e) => handleQuerySave(e)}
            disabled={saveLoading || !queryText.length > 0}
            style={{
              flex: "1 0 10%",
              whiteSpace: "nowrap",
            }}
          >
            {saveStatus.message ? saveStatus.message : "Save Query"}
          </button>

        </div>
        <div>
          <button
            type="button"
            className="btn btn-danger mx-2"
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            Show Documentation
          </button>

          {generalQueries.length > 0 && (
            <button
              type="button"
              className="btn btn-danger mx-2"
              onClick={() => history.push(`/general-queries/${sportType}/`)}
            >
              Useful Queries {`(${generalQueries.length})`}
            </button>
          )}

          {todaysQueries.length > 0 && (
            <button
              type="button"
              className="btn btn-danger mx-2"
              onClick={() => history.push(`/todays-queries/${sportType}/`)}
            >
              Todays Queries {`(${todaysQueries.length})`}
            </button>
          )}

          {props.userData.isStaff && (
            <>
              <button
                type="button"
                className="btn btn-danger mx-2"
                onClick={() => setOpenGeneralUseQueryModal(true)}
              >
                Add Useful Query
              </button>
              <button
                type="button"
                className="btn btn-danger mx-2"
                onClick={() => setOpenTodaysQueryModal(true)}
              >
                Add Todays Use Query
              </button>
            </>
          )}
        </div>

        <div
          className="modal fade"
          id="exampleModalLong"
          style={{ paddingTop: 100 }}
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Documentation
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <SportDocumentation sportType={sportType} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-anim">
          <DualRing size={100} color="#30aaba" />
        </div>
      )}
      {htmlStr && <div className="result-set">{ReactHtmlParser(htmlStr)}</div>}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleClose}
        id="invalid-query"
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>

      <CreateGeneralQueryModal
        open={openGeneralUseQueryModal}
        setOpen={setOpenGeneralUseQueryModal}
        fetchData={() => {}}
        sportType={sportType}
      />

      <CreateTodaysQueryModal
        open={openTodaysQueryModal}
        setOpen={setOpenTodaysQueryModal}
        fetchData={() => {}}
        sportType={sportType}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: { ...state },
    isAuthenticated: state.isAuthenticated,
    firstVisit: state.firstVisit,
    jwtCookie: state.jwtCookie,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAuthenticate: () => dispatch(visitAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcessQueryPage);
