import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({ label, options, selectedOptions, setSelectedOptions, styles }) {
  const [localOptions, setLocalOptions] = React.useState([...options]);
  // const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ ...styles }}>
        <InputLabel id={`${label}-multiple-checkbox-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-multiple-checkbox-label`}
          id={`${label}-multiple-checkbox`}
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {localOptions.map((option) => (
            <MenuItem key={option?.name} value={option?.value}>
              <Checkbox checked={selectedOptions.indexOf(option?.value) > -1} />
              <ListItemText primary={option?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
