import React from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { createPaymentSession } from '../../Apis/PaymentApi'
import { PREVIOUS_PAYMENT_URL } from '../../constants/localStorage.constants'
import { Banner, Body, BuyButton, DeleteBtn, Description, Heading, MainSection, PointsText, SellerInfoSection, UsernameText } from './ProductCard.styles'
import { deleteMartItemsCollection } from "../../Apis/MartApi"

const ProductCard = ({ data, store, isCollection = false, callback=()=>{} }) => {
  const history = useHistory();

  const handleBuy = async () => {
    if(!store.isAuthenticated) {
      history.replace("/login")
      return null;
    }

    console.log(data?.id)
    history.replace(`/payment/${data?.id}/${isCollection ? '?collection=true' : ''}`)

    // const res = await createPaymentSession(data);
    // if (res?.url) {
    //   localStorage.setItem(PREVIOUS_PAYMENT_URL, res?.url)
    //   window.location.replace(res?.url);
    // }
  }

  const handleDelete = async () => {
    const res = await deleteMartItemsCollection(data?.id);
    if (res) {
      callback();
    }
  }

  return (
    <Body>
      {isCollection && (
        <>
          <DeleteBtn onClick={() => handleDelete()}>x</DeleteBtn>
          <Banner>Collection</Banner>
        </>
      )}
      <MainSection>
        <Heading>
          <span>Description: </span>
        </Heading>
        <Description>
          {data?.description}
        </Description>
      </MainSection>

      <div style={{ textAlign: 'center' }}>
        <SellerInfoSection>
          <PointsText>
            <span>Seller: </span>
            {isCollection ? (
              <small>
                <Link to={`/mart/user/${data.user.id}`}>{data?.seller_name}</Link>
              </small>
            ) : (
              <small>
                <Link to={`/mart/user/${data.query.user.id}`}>{data.query.user.name}</Link>
              </small>
            )}
          </PointsText>


          {isCollection ? (
            <PointsText>
              <span>Type: </span>
              <small>
                Colletion
              </small>
            </PointsText>
          ) : (
            <PointsText>
              <span>Type: </span>
              <small>
                {data.query.sport_type}
              </small>
            </PointsText>
          )}
        </SellerInfoSection>

        <BuyButton
          className="btn"
          onClick={() => handleBuy()}
        >
          {`Buy (${data?.price}$)`}
        </BuyButton>
      </div>
    </Body>
  )
}


const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

const mapDispatchToProps = (dispatch) => {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);

// export default ProductCard