const docs = {
    "Shortcuts": [
        {name: "AASB - after all-star break - Ex: 1st game after the All-Star break and playing at home", url: "p:BASB and AASB and H"},
        {name: "AWP - away winning percentage - Ex: All team's 2022 road wins, losses, and WP", url: "R(team),tS(W),tS(L),R(AWP)@team and season=2022 and A"},
        {name: "BASB - before all-star break - Ex: Last game before All-Star break", url: "BASB and n:AASB"},
        {name: "BL - biggest lead - Ex: In the last game, both team's biggest lead was 1, and the team won by one", url: "p:BL=1 and po:BL>1 and p:margin=1 and season>=2018"},
        {name: "BPRA - bull pen runs allowed - Ex: Team's pen allowed more runs then opp's pen in playoff game", url: "tA(BPRA)<oA(BPRA) and playoffs=1"},
        {name: "BS - starter's bad start - Ex: Last game starter stunk and team lost by at least 5", url: "p:BS and p:margin<=-5 and season>=2018"},
        {name: "DAY - day game - Ex: Day game today and the next game is a day game against a different team", url: "DAY and n:DAY and no:team!=o:team"},
        {name: "DIV - division game - Ex: Division Game, 1st game of series, away after a home loss ", url: "DIV and FGS and A and p:HL and season>2018"},
        {name: "FGS - first game of series - Ex: 1st series game and team has a better winning percentage", url: "FGS and WP>o:WP"},
        {name: "HPR - hits per run - Ex: Team off a game where they averaged at least 10 hits to get a run", url: "p:HPR>=10"},
        {name: "HPU - home plate ump - Ex: How teams do when Angel Hernandez is behind the dish", url: "HPU='Angel Hernandez and H'"},
        {name: "HR - home runs - Ex: Teams averaging at least 3 HR's in the last 2, while the opp averaged 1 or fewer dingers", url: "tA(HR,N=2)>=3 and oA(HR,N=2)<=1"},
        {name: "HWP - home winning percentage - Ex: Team has won 70% of its home game and are a home dog", url: "HWP>70 and HD"},
        {name: "IL - innings led - Ex: Last game, led after more innings and lost", url: "p:IL>po:IL and p:L and season>=2018"},
        {name: "IT - innings tied - Ex: Last game, the team was tied for 9 innings and lost by more than a run", url: "p:IT=9 and p:margin<=-2"},
        {name: "LGS - last game of series - Ex: Team win 1st 3 of a 3-game series and is an away dog", url: "LGSLGS and SGS=3 and p:W and pp:W and AD and season>2018"},
        {name: "LOB - left on base - Ex: Over the last 2, team averaged under 4 LOB and won both games", url: "tA(LOB,N=2)<=4 and streak>=2"},
        {name: "M1 - margin after the first inning - Ex: Team was winning by 4 after 1 in the last game and lost", url: "p:M1>4 and p:L"},
        {name: "M2 - margin after the second inning - Ex: Team winning by 2 after 1 and losing after 2 in last game", url: "p:M1>2 and p:M2<0"},
        {name: "M3 - margin after the third inning - Ex: Team winning by 3 after 1 and losing after 3 in last game", url: "p:M1>3 and p:M3<0"},
        {name: "M4 - margin after the fourth inning - Ex: Team had lead after 4 in last game and was losing after 5", url: "p:M4>1 and p:M5<0"},
        {name: "M5 - margin after the fifth inning - Ex: Last game of a 4-game series, team had the lead after 5 and lost", url: "SGS=4 and LGS and p:M5>0 and p:L"},
        {name: "M6 - margin after the sixth inning - Ex: Dog in last game losing by more than 3 after 6 and won", url: "p:M6<-3 and p:DW"},
        {name: "M7 - margin after the seventh inning - Ex: Away team in new series after last game, down by at least 2 after 7 and win at home as a dog", url: "p:M7<-1 and p:HW and FGS and  A"},
        {name: "M8 - margin after the eigth inning - Ex: Winning 1-0 after 8 in last game and lost", url: "p:M8=1 and p:S8=1 and p:L"},
        {name: "M9 - margin after the ninth inning - Ex: Average team margin at the end of 9, not including extra inning games", url: "tA(M9)@team and season-2022 and not X"},
        {name: "MRI - count of multiple-run innings - Ex: Team had at least 4 innings with crooked number and lost their last game", url: "p:MRI=>4 and p:L"},
        {name: "NDIV - non-division game - Ex: Last game was a non-division road game and the last game of a series and the next game is a road division game", url: "p:A and p:NDIV and LGS and DIV and A"},
        {name: "NGT - night game - Ex: Sunday Night game where the favorite is between a -101 and -191 fav", url: "NGT and day=Sunday and F and line<-110"},
        {name: "NQS - starter's non-quality start - Ex: Starter's last start was not a quality start and the team lost by at least 5 runs", url: "s:NQS and s:margin>=5 and season>=2018"},
        {name: "PBRS - percentage of base runners that scored - Ex: Team off a win with a lower % of baserunners who scored than the opponent", url: "p:PBRS<po:PBRS and p:W"},
        {name: "PO - playoff game - Ex: Home dog in playoffs with a better season winning percentage", url: "PO and WP>o:WP and HD"},
        {name: "PU - pitchers used - Ex: 1 pitcher used in last game and allowed no runs, i.e. a shutout", url: "p:PU=1 and po:runs=0"},
        {name: "QS - quality start - Ex: Starter off a quality start as a home dog and lost, and is a home dog again", url: "s:QS and s:HDL and HD"},
        {name: "R1 - runs in inning 1 - Ex: Team has a 3-run lead after 1 and lost", url: "p:R1-po:R1>=3 and p:L"},
        {name: "R2 - runs in inning 2 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R2-po:R2>=3 and p:L"},
        {name: "R3 - runs in inning 3 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R3-po:R3>=3 and p:L"},
        {name: "R4 - runs in inning 4 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R4-po:R4>=3 and p:L"},
        {name: "R5 - runs in inning 5 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R5-po:R5>=3 and p:L"},
        {name: "R6 - runs in inning 6 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R6-po:R6>=3 and p:L"},
        {name: "R7 - runs in inning 7 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R7-po:R7>=3 and p:L"},
        {name: "R8 - runs in inning 8 - Ex: Team has a 3-run lead after 2 and lost", url: "p:R8-po:R8>=3 and p:L"},
        {name: "R11 - runs in inning 11 - Ex: Columns display of all inning runs", url: "tS(R1) as '1st inn runs',tS(R2) as '2nd inn runs',tS(R3) as '3rd inn runs',tS(R4) as '4th inn runs',tS(R5) as '5th inn runs',tS(R6) as '6th inn runs',tS(R7) as '7th inn runs',tS(R8) as '8th inn runs',tS(R9) as '9th inn runs'@team=Yankees and season=2022"},
        {name: "REG - regular season game - Ex: Home dogs off a win as a +250 higher dog or higher in the regular season", url: "REG and HD and p:line>250 and p:W"},
        {name: "RG3 - rubber game of a 3-game series - Ex: Rubber game of where team lost last game by 2 or more", url: "RG3 and p:margin>=2 and season>=2018"},
        {name: "RMAX - runs in the highest scoring inning - Ex: Team's off a game where they had a 6+ run inning and lost", url: "p:RMAX>5 and  p:L"},
        {name: "S1 - team runs after 1 inning - Ex: Team's off a game with a 5-run 1st inning", url: "p:S1>=5"},
        {name: "S2 - team runs after 2 innings - Ex: Nationals 2nd inning runs in 2022", url: "date,team,S2@team=Nationals and season=2022"},
        {name: "S3 - team runs after 3 innings - Ex: Team's that had over 4 runs thru 3 in 2022", url: "p:S3>4 and season=2022"},
        {name: "S4 - team runs after 4 innings - Ex: Teams that had no score last game after 4 and the game went over", url: "p:S4+po:S4<1 and p:O"},
        {name: "S5 - team runs after 5 innings - Ex: Teams that had the lead after 5 last game and lost", url: "p:S5>po:S5 and p:L"},
        {name: "S6 - team runs after 6 innings - Ex: Teams off a game with 7 runs after 6 that went under the total", url: "p:S6+po:S6>7 and p:U"},
        {name: "S7 - team runs after 7 innings - Ex: Teams that scored over 10 runs after 7 in their last game", url: "p:S7>10"},
        {name: "S8 - team runs after 8 innings - Ex: Teams that had no runs after 8 in a game that went over the total", url: "p:S8<=1 and p:O"},
        {name: "S9 - team runs after 9 innings - Ex: Mets runs after 9 in 2022", url: "date,S9@team=Mets and season=2022"},
        {name: "S10 - team runs after 10 innings - Ex: Mets runs after 10 in 2022", url: "date,S10@team=Mets and season=2022 and X"},
        {name: "S11 - team runs after 11 innings - Ex: Mets runs after 11 in 2022", url: "date,S11@team=Mets and season=2022 and p:X"},
        {name: "SERA - starter's era - Ex: Starter had over a 9 ERA in their last game and won", url: "s:SERA>9 and s:W"},
        {name: "SF - runs scored first before opponent scored - Ex: Teams off a game where they scored double-digits before the other team scored", url: "p:SF>=10"},
        {name: "SG - series game - Ex: World Series game 7s with the a short fav of at most -130", url: "SG=7 and playoffs=1 and league!=o:league and F and line>=-130"},
        {name: "SGS - number of games in series - Ex: Teams in game 2 of a 2-game series and team won the 1st game", url: "SGS=2 and SG=2 and p:W"},
        {name: "SHA - starter's hits allowed - Ex: In the starter's last outing, he gave up at least 6 fewer hits than the opponent's starter", url: "s:SHA+6<os:SHA and season>=2018"},
        {name: "SHF - starter's batters faced - Ex: The starter faced 3 or fewer batters in his last outing", url: "s:SHF<=3"},
        {name: "SII - scored in innings - Ex: In the last gamem the team scored at least a run in fewer innings then the opponent and won and they're playing the same team again", url: "p:SII<op:SII and p:W and po:team=o:team"},
        {name: "SNP - starter's pitches - Ex: The starter threw under 75 pitches in the last game while the opponent's starter threw over 100", url: "s:SNP<75 and os:SNP>110"},
        {name: "SPB - team strikes per ball - Ex: The starter's strike to ball ratio was over 2-1 in the last outing and the team is a road fav", url: "s:SPB>2 and AF"},
        {name: "SRA - Starter runs allowed - Ex: The starter allowed 6 runs last game against a starter who allowed no runs", url: "s:SRA>=6 and os:SRA=0"},
        {name: "SSPB - starter's strikes per ball", url: "A(SSPB@starter and season,N=3)<=1.25 and season>=2016"},
        {name: "STDHPR - season-to-date hits per run - Ex: Starter's hit per run by team through today", url: "R(STDHPR) as 'Hits per Run',R(team) as 'Team'@team and season=2022"},
        {name: "STDSERA - season-to-date starter ERA - Ex: Starter's ERA by team this season", url: "R(STDSERA) as 'Starter ERA',R(team) as 'Team'@team and season=2022"},
        {name: "STDSOPW - strike outs per walk - Ex: The team's Ks per walks is better then the opposing teams and the stater whiffed over 10 last game", url: "STDSOPW>o:STDSOPW and so:strike outs>10 and season>=2018"},
        {name: "STDSSOPW - season-to-date starter's strike outs per walk - Ex: The starter's Ks per walks is better then the opposing starter's and the stater whiffed over 10 last game", url: "STDSOPW>o:STDSOPW and so:strike outs>10 and season>=2018"},
        {name: "STDSSPB - starter's season-to-date something", url: "(STDSSPB-0.25)>A(STDSSPB@season) and season>=2020"},
        {name: "STDSWHIP - starter's season-to-date WHIP - Ex: Away dogs after scoring double-digit runs againt a starter with over a 1.5 WHIP", url: "AD and p:runs>=9 and o:STDSWHIP>1.5 and season>=2017 and FGS"},
        {name: "STL - southpaw - Ex: White Sox against southpaws", url: "team=White Sox and o:STL and season>=2020"},
        {name: "STR - righty starter - Ex: Teams facing a righty as a home dog after beating a righty last game at home", url: "o:STR and season>=2021 and HD and p:STR and p:HW"},
        {name: "SWA - starter's walks allowed - Ex: Starters off a game with no walks and are averaging 3 of fewer walks per start for the season", url: "s:SWA=0 and A(SWA@starter and season)<=3"},
        {name: "SWHIP - starter's WHIP - Ex: Starter had over a 2.5 WHIP last game and threw under 5 innings", url: "s:SWHIP>2.5 and s:SIP<5 and season=2022"},
        {name: "TT - times tied - Ex: Last game of searies and team won last game by 2 or more without being tied at any point after 0-0", url: "p:TT=0 and p:margin>=2 and LGS and season>=2018"},
        {name: "WOW - walk off win - Ex: Yankees walk-offs", url: "p:WOW and team=Yankees"},
        {name: "WP - winning percentage - Ex: Home dogs with a better winning percentage than the opponent", url: "WP>o:WP and HD"},
        {name: "X - extra innings - Ex: Team's off an extra inning win", url: "X and p:W"},
        {name: "XBH - extra base hits - Ex: Team's averaging more than 6 extra base hits in their last 3 games", url: "tA(XBH,N=3)>6"},
        {name: "SHRA - starter home runs allowed - Ex: Stater yields more than 2 home runs last start", url: "s:SHRA>2 and season=2021"},
        {name: "SIP - starter innings pitched - Ex: Starter off an outing where he didn't make it out of the 1st inning", url: "s:SIP<1 and season>=2019"},
        {name: "SPT - starter pitches thrown - Ex: Starter threw over 100 pitches last game in an away win", url: "s:SPT>110 and s:AW and A and season>=2019"},
        {name: "SSO - starter strike outs thrown - Ex: Starter fanned over 10 last game in a home win", url: "s:SSO>10 and s:HW and season>=2019"},
        {name: "SGO - stater ground outs - Ex: Starter had over 15 ground outs last start in a home win", url: "s:SGO>15 and s:HW and season>=2019"},
        {name: "SFO - starter fly outs - Ex: Starter had over 15 ground outs last start in a home win", url: "s:SFO>15 and s:HL and season>=2019"},
    ],
    "Parameters": [
        {name: "at bats", url: "at bats>75"},
        {name: "attendance", url: "p:attendance>60000 and attendance<20000"},
        {name: "average", url: "p:average>.330 and p:AL"},
        {name: "away site streak", url: "away site streak>8 and A"},
        {name: "biggest lead - (BL)", url: "p:biggest lead>7 and p:L"},
        {name: "bull pen runs allowed", url: "p:bull pen runs allowed>6 and pp:bull pen runs allowed>6"},
        {name: "complete game - [data started in 2021]", url: "S:complete game>0 and S:W and season>=2021 "},
        {name: "conditions", url: "team=Braves and season=2022 and 'Cloudy' in conditions"},
        {name: "data date", url: "date,team,o:team,data date,data time@date=today-1 and H"},
        {name: "data time", url: "date,team,o:team,data date,data time@date=today-1 and H"},
        {name: "date", url: "date=today and H"},
        {name: "day", url: "HF and day=Sunday and n:A"},
        {name: "division", url: "HF and WP>o:WP and n:division=no:division"},
        {name: "double header", url: "p:double header=1 and p:FL and double header=2 and F"},
        {name: "double plays - data still be loaded", url: ""},
        {name: "doubles", url: "p:doubles>5 and D"},
        {name: "duration", url: "p:duration>3.5 and p:HFL and A"},
        {name: "earned runs", url: "p:earned runs>10 and p:PU>5 and p:L  and A"},
        {name: "errors", url: "p:errors>4"},
        {name: "full name", url: "R(team) as 'Team',R(full name) as 'Full Name',tS(W) as 'Ws',tS(L) as 'Ls',tS(O) as 'Overs',tS(U) as 'Unders'@team and season=2022 and date<today"},
        {name: "game number", url: "WP>o:WP and game number>40 and HD and oS:L"},
        {name: "hit batters", url: "oS:hit batters=3 and oS:W"},
        {name: "hits", url: "p:hits>18 and p:L"},
        {name: "home runs - (HR)", url: "p:HR>5 and p:A and AD"},
        {name: "home site streak", url: "home site streak>10 and D"},
        {name: "inning runs", url: "ate,team,inning runs,o:team,o:inning runs@season=2022 and date<today"},
        {name: "innings led", url: "p:innings led>7 and p:HL and A"},
        {name: "innings tied", url: "o:innings tied=0 and p:OW"},
        {name: "league", url: "league='AL' and o:league='NL' and H"},
        {name: "left on base - (LOB)", url: "p:left on base>28 and p:HFW"},
        {name: "location", url: "R(team) as 'Team',R(full name) as 'Name',R(location) as 'Stadium'@team and season=2022"},
        {name: "losses", url: "Not ready yet"},
        {name: "margin", url: "tA(margin) as 'Average Margin'@team and season=2022"},
        {name: "month", url: "month=5 and tS(H)>tS(A)+7 and A"},
        {name: "opponents - [Not ready]", url: "team=White Sox and 'Cubs' not in opponents and o:team=Cubs"},
        {name: "ou margin", url: "tA(ou margin) as 'Average Over/Under Margin'@team and season=2022"},
        {name: "ou streak - [Not ready]", url: ""},
        {name: "pitchers used - (PU)", url: "tS(PU,N=3)>20 and p:W"},
        {name: "plate appearances", url: "tA(plate appearances)>oA(plate appearances)+5"},
        {name: "playoffs", url: "playoffs=1 and season=2021 and F"},
        {name: "rest", url: "rest>o:rest and line>150 and op:A "},
        {name: "run line streak - [Not ready]", url: "date,team,run line streak@run line streak>3"},
        {name: "runs", url: "tA(runs),oA(runs)@team and season=2022"},
        {name: "saves - [Only 2022 and after]", url: "R(team),tS(saves)@team and season=2022 and date<today"},
        {name: "scored first - (SF)", url: "p:scored first>7 and p:L"},
        {name: "season", url: "AF and season=2022"},
        {name: "series game - (SG)", url: "series game=2 and p:L and HD and 130<line<160"},
        {name: "series games - (SGS)", url: "series game=3 and series games=4 and tS(L,N=2)=2 and HF and line>-140"},
        {name: "singles - [Not ready]", url: "p:singles=p:hits and p:AW"},
        {name: "site", url: "site=away and p:runs=0 and po:team=o:team"},
        {name: "site streak", url: "site=away and p:runs=0 and po:team=o:team and F"},
        {name: "start time", url: "start time<1130 and H"},
        {name: "starter", url: "starter=Justin Verlander and HD"},
        {name: "starter earned runs - [Not ready]", url: ""},
        {name: "starter losses - [Not ready]", url: ""},
        {name: "starter rest - [Not ready]", url: ""},
        {name: "starter runs allowed - (SRA)", url: "p:starter runs allowed>6 and p:HFL and p:line<-250"},
        {name: "starter throws - (STL or STR)", url: "starter throws='R' and team=Mets and season=2022"},
        {name: "starter wins - [Not ready]", url: ""},
        {name: "stolen bases - [Data from 2021 on]", url: "season>=2021 and p:stolen bases>3"},
        {name: "streak", url: "streak>9 and o:streak<-9"},
        {name: "strike outs", url: "p:strike outs>o:strike outs+10 and p:W and po:team=o:team"},
        {name: "team", url: "team=Yankees and season=2022"},
        {name: "temperature", url: "temperature<40 and AD"},
        {name: "time zone", url: "month=5 and WP>o:WP and time zone='E' and o:time zone='P' and A"},
        {name: "two out rbi - [Not ready]", url: ""},
        {name: "umpires", url: "'Angel Hernandez' in umpires and AF and temperature>90"},
        {name: "walks", url: "po:walks>10 and p:ADW"},
        {name: "wind", url: "wind>20 and H"},
        {name: "wind direction", url: "wind>20 and 'out' in wind direction and H"},    
    ],
    "Prefixes": [
        {name: "op: (opp's previous game)", url: "p:runs>=10 and op:runs>=10"},
        {name: "p: (team's previous game)", url: "p:L and p:BL>6"},
        {name: "t: - team (if omitted, assumed)", url: "t:team=Giants and t:site=home"},
        {name: "o: - opponent", url: "team=Giants and o:team=Dodgers and o:line<-200"},
        {name: "p: - previous game", url: "p:runs>=10 and op:runs>=10"},
        {name: "op: - opponent's previous game", url: "p:runs>=10 and op:runs>=10"},
        {name: "P: - previous game against opponent", url: "P:HDW and H"},
        {name: "n: - next game", url: "n:date>today+1"},
        {name: "on: - opponent's next game", url: "op:A and H and on:A"},
        {name: "N: - next game against opponent", url: "H and N:A"},
        {name: "s: - starter's previous start", url: "Starter:max:s:complete game>0 and so:runs=0 and HF"},
        {name: "S: - starter's previous start against opponent", url: "Starter:max:S:complete game>0 and So:runs=0 and HF"},
    ]
}

export {docs};