import {
  Box,
  Button,
  Input,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { getMartItems, getSellerMartItems, postMartItemsCollection } from "../../../Apis/MartApi";
import { connect } from "react-redux";
import MultipleSelectCheckmarks from "../../Inputs/MultipleSelectCheckmarks";

const ManageCollectionModal = ({
  fetchData,
  open,
  setOpen,
  collectionId = null,
  ...props
  // currentEditCollection,
  // setCurrentEditCollection,
}) => {
  const [loading, setLoading] = React.useState(false);

  const [userMartItems, setUserMartItems] = React.useState([]);
  const [currentEditCollection, setCurrentEditCollection] = React.useState({
    id: null,
    user: null,
    description: "",
    price: 0,
    mart_items: [],
  });

  const setSelectedOptions = (value) => {
    setCurrentEditCollection({ ...currentEditCollection, mart_items: [...value] })
  }

  const handleSubmit = async (event, queryId) => {
    event.preventDefault();
    setLoading(true);
    const data = { ...currentEditCollection, user: props?.authState?.id };
    const res = await postMartItemsCollection(data, queryId);
    if(res) {
      setOpen(false);
      fetchData();
    }
    setLoading(false);
  };


  const handleEditClose = () => {
    // setCurrentEditCollection({});
    setOpen(false);
  };

  const fetchUserMartItemsData = async () => {
    setLoading(true);
    const data = await getSellerMartItems(props?.authState?.id);
    console.log(data);
    if(data) {
      setUserMartItems(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(collectionId) {
      console.log("EDIT: ", collectionId)
    }
    if(props?.authState?.isAuthenticated) {
      fetchUserMartItemsData()
    }
  }, [collectionId, props?.authState])


  return (
    <Modal
      open={open}
      onClose={() => handleEditClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3 className="fs-3">
          {collectionId ? `Updating collection #${collectionId}` : "Create New Collection"}
        </h3>

        <form onSubmit={(e) => handleSubmit(e, currentEditCollection.id)}>
          <Input
            style={{ width: "100%", marginBottom: "20px" }}
            type="number"
            name="price"
            label="Price"
            variant="standard"
            value={currentEditCollection.price}
            onChange={(e) =>
              setCurrentEditCollection({
                ...currentEditCollection,
                price: e.target.value,
              })
            }
            required
          />

          <TextareaAutosize
            aria-label="Description"
            minRows={3}
            placeholder="Description"
            name="description"
            value={currentEditCollection.description}
            onChange={(e) =>
              setCurrentEditCollection({
                ...currentEditCollection,
                description: e.target.value,
              })
            }
            style={{
              width: "100%",
              marginBottom: "20px",
              borderRadius: "10px",
              borderColor: "rgba(0, 0, 0, 0.42)",
              padding: "10px",
            }}
          />

          <MultipleSelectCheckmarks
            label={"Mart Items"}
            selectedOptions={currentEditCollection?.mart_items}
            setSelectedOptions={setSelectedOptions}
            options={userMartItems.map(item => ({ name: item?.description, value: item?.id }))}
            styles={{ width: "100%", mb: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {collectionId ? "Update" : "Create"}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    jwtCookie: state.jwtCookie,
    authState: state,
  };
};

export default connect(mapStateToProps)(ManageCollectionModal);
