const docs = {
    "Shortcuts": [
        {name: "H", url: "H and (tA(points)+oA(points))<total"},
        {name: "A", url: "A and (tA(points)+oA(points))<total"},
    ],
    "Prefixes": [
        {name: "op: (opp's previous game)", url: "p:points>=38 and op:points>=38 and p:PO and op:PO"},
        {name: "p: (team's previous game)", url: "p:points>=38 and op:points>=38 and p:PO and op:PO"},
    ],
    "Parameters": [
        {name: "assists", url: "p:assists>27 and p:turnovers<5"},
        {name: "blocks (>=2021)", url: "season=2021 and blocks>25"},
        {name: "conference", url: "team=Lakers and conference=o:conference and season>2019 and AF"},
    ]
}

export {docs};