import {
    Box,
    Button,
    Modal,
    TextareaAutosize,
    TextField
} from "@material-ui/core";
import React, { useEffect } from "react";
import { postMartItem } from "../../../Apis/MartApi";

const AddForSellModal = ({
    fetchData,
    open,
    setOpen,
    currentMonetizeQuery,
    setCurrentMonetizeQuery,
}) => {
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        queryId: null,
        price: 0,
        description: '',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const res = await postMartItem(formData);
        if (res) {
            setOpen(false);
            fetchData();
        }
        setLoading(false);
    };

    const handleClose = () => {
        setCurrentMonetizeQuery({});
        setOpen(false);
    };

    useEffect(() => {
        setFormData({
            ...formData,
            queryId: currentMonetizeQuery.id,
            price: currentMonetizeQuery?.mart_item?.price || 0,
            description: currentMonetizeQuery?.mart_item?.description || "",
        })
    }, [currentMonetizeQuery])
    

    return (
        <Modal
            open={open}
            onClose={()=>handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    maxWidth: "800px",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h3 className="fs-3">Add SDQL For Sell</h3>
                <form onSubmit={(e) => handleSubmit(e)}>
                <TextField
                    style={{ width: "100%", marginBottom: "20px" }}
                    type="number"
                    name="price"
                    label="Price"
                    variant="standard"
                    value={formData.price}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            price: e.target.value,
                        })
                    }
                    required
                />

                <TextareaAutosize
                    aria-label="Description"
                    minRows={3}
                    placeholder="Description"
                    name="description"
                    value={formData.description}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            description: e.target.value,
                        })
                    }
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "10px",
                        borderColor: "rgba(0, 0, 0, 0.42)",
                        padding: "10px",
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                >
                    {currentMonetizeQuery.is_for_sell ? (
                        "Update"
                    ) : (
                        "Add"
                    )}
                </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default AddForSellModal;
