import {
  Box,
  Button,
  Modal,
  TextField,
  TextareaAutosize
} from "@material-ui/core";
import React, { useEffect } from "react";
import { deleteMartItem } from "../../../Apis/MartApi";

const RemoveFromSellModal = ({
    fetchData,
    open,
    setOpen,
    currentMonetizeQuery,
    setCurrentMonetizeQuery,
}) => {
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const res = await deleteMartItem(currentMonetizeQuery.id);
        if (res) {
            setOpen(false);
            fetchData();
        }
        setLoading(false);
    };

    const handleClose = () => {
        setCurrentMonetizeQuery({});
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={()=>handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    maxWidth: "800px",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h3 className="fs-3">Remove SDQL From Sell</h3>
                <form onSubmit={(e) => handleSubmit(e)}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                >
                  Remove
                </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default RemoveFromSellModal;
