import styled from "styled-components";

export const Body = styled("article")`
    margin-top: 150px;
`;

export const MainCont = styled("section")`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px 20px;
`;

export const LeftCont = styled("div")`
    flex: 1 1 50%;
    white-space: nowrap;
`;

export const RightCont = styled("div")`
    flex: 1 1 50%;
    margin-top: 80px;
    
    #paypal-buttons {
      position: relative;
    }

    #paypal-buttons.disabled::before {
      content: "";
      position: absolute;
      background: #0000001a;
      width: 100%;
      height: 100%;
      border-radius: 25px;
      z-index: 1000;
    }
`;

export const DetailsList = styled("ul")`
    margin: 20px 0px;
`;

export const Heading = styled("h1")`
    color: #555555;
`;