import React, { useEffect, useState } from "react";
import { getUserData, updateUserProfile } from "../../Apis/UserApi";
import AvatarImg from "../../assets/images/avatar.png";
import Navbar from "../../Component/Navbar";
import {
  BioSection,
  Content,
  Header,
  Heading,
  MainBody,
  MutedText,
  UserAvatar,
  UserAvatarCont,
  UserBannerCont,
  UserInfoCont,
  UserName,
} from "./EditProfilePage.styles";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextareaAutosize,
} from "@material-ui/core";

const EditProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const [showBio, setShowBio] = useState(false);
  const [userData, setUserData] = useState({});

  const fetchUserData = async () => {
    setLoading(true);
    const data = await getUserData();
    if (data) {
      setUserData(data);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      bio: "",
      sms_updates: false,
      email_updates: false,
    },
    onSubmit: async (values) => {
      console.log(values)
      setLoading(true);
      const data = await updateUserProfile(values);
      if (data) {
        fetchUserData();
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: userData.name,
      phone: userData.phone,
      bio: userData.bio,
      sms_updates: userData.sms_updates,
      email_updates: userData.email_updates,
    })
    console.log(userData)
  }, [userData]);

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <article>
      <Navbar />

      <MainBody>
        <Header>
          <UserBannerCont></UserBannerCont>
          <UserInfoCont>
            <UserAvatarCont>
              <UserAvatar src={AvatarImg} alt="avatar" />
              <div>
                <UserName>{userData.name}</UserName>
                <MutedText>
                  Total SDQLs {`(${userData.mart_items_count || "0"})`}
                </MutedText>
                <MutedText
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowBio(!showBio)}
                >
                  Show Bio
                </MutedText>
              </div>
            </UserAvatarCont>

            <BioSection className={`${showBio ? "show" : ""}`}>
              <MutedText>{userData.bio || "No information given"}</MutedText>
            </BioSection>
          </UserInfoCont>
        </Header>

        <Content>
          <Heading>Edit Profile</Heading>

          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Name</InputLabel>
              <Input {...formik.getFieldProps("name")}/>
              <FormHelperText id="my-helper-text">
                Enter your display name
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel>Phone</InputLabel>
              <Input {...formik.getFieldProps("phone")}/>
              <FormHelperText id="my-helper-text">
                Enter your phone number
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <TextareaAutosize
                placeholder="Bio"
                minRows={5}
                {...formik.getFieldProps("bio")}
              />
              <FormHelperText id="my-helper-text">
                Tell people about you
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel>Receive SMS Updates</InputLabel>
              <Checkbox {...formik.getFieldProps("sms_updates")} checked={formik.values.sms_updates}/>
              <FormHelperText id="my-helper-text">
                when the user has an active query today or this week
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel>Receive Email Updates</InputLabel>
              <Checkbox {...formik.getFieldProps("email_updates")} checked={formik.values.email_updates}/>
              <FormHelperText id="my-helper-text">
                when the user has an active query today or this week
              </FormHelperText>
            </FormControl>

            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              Submit
            </Button>
          </form>
        </Content>
      </MainBody>
    </article>
  );
};

export default EditProfilePage;
