import { Button, CircularProgress, List, ListItem, ListItemText, MenuItem, Select, Slider, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMartItems, getMartSellers } from "../../Apis/MartApi";
import Navbar from "../../Component/Navbar";
import ProductCard from "../../Component/ProductCard";
import {
  Content,
  FieldLabel,
  Heading,
  LoaderCont,
  MainBody,
  UserList,
  QueryField,
  Sidebar,
  Footer,
  CustomLink,
  ListImg,
} from "./MartSellersPage.styles";
import { Link } from "react-router-dom";
import { ListItemButton } from "@mui/material";

let timeOut;

const MartSellersPage = () => {
  const [martUsers, setMartUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    query: null,
    maxPrice: 150,
    sportType: 'All',
  });

  const handleFilterChange = async (name, value) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      setFilters({ ...filters, [name]: value });
    }, 500);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getMartSellers();
    console.log(data);
    if (data) {
      setMartUser(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(filters);
    fetchData();
  }, [filters]);

  return (
    <article>
      <Navbar />

      <MainBody>
        {/* <Sidebar>
          <Heading>Filters</Heading>

          <div>
            <QueryField
              label="Enter your query..."
              fullWidth
              // value={filters.query}
              onChange={(e) => handleFilterChange("query", e.target.value)}
            />
          </div>

          <div>
            <FieldLabel>Max Price</FieldLabel>
            <Slider
              defaultValue={filters.maxPrice}
              aria-label="Price"
              valueLabelDisplay="auto"
              min={5}
              max={150}
              // value={filters.maxPrice}
              onChange={(e, value) => handleFilterChange("maxPrice", value)}
              step={5}
            />
          </div>

          <div>
            <FieldLabel>Sport Type</FieldLabel>
            <Select
              fullWidth
              label="Sport Type"
              value={filters.sportType}
              onChange={(e) => handleFilterChange("sportType", e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="NFL">NFL</MenuItem>
              <MenuItem value="NCAAFB">NCAAFB</MenuItem>
              <MenuItem value="NBA">NBA</MenuItem>
              <MenuItem value="NHL">NHL</MenuItem>
              <MenuItem value="MLB">MLB</MenuItem>
              <MenuItem value="NCAABB">NCAABB</MenuItem>
              <MenuItem value="WNBA">WNBA</MenuItem>
              <MenuItem value="NFLPRE">NFLPRE</MenuItem>
              <MenuItem value="ATP">ATP</MenuItem>
              <MenuItem value="CFL">CFL</MenuItem>
              <MenuItem value="UFC">UFC</MenuItem>
              <MenuItem value="UFCW">UFCW</MenuItem>
            </Select>
          </div>
        </Sidebar> */}

        <Content>
          {isLoading ? (
            <LoaderCont>
              <CircularProgress />
            </LoaderCont>
          ) : (
            <>
              <List>
                {martUsers.length > 0 ? (
                  <>
                    {martUsers.map((item) => (
                      <ListItem disablePadding>
                        <ListItemButton>
                          <CustomLink key={item?.id} to={`/mart/user/${item?.id}/`}>
                           <span>{item?.name} - ({item?.mart_items_count})</span>
                           <ListImg src="./GTDCirclewhite.jpg" alt="GTDCirclewhite"/>
                          </CustomLink>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <ListItem disablePadding>
                    <ListItemButton>
                      No users to show
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding>
                  <ListItemButton>
                    <Link to={`/mart/all/`}>
                      Queries for sale of all sellers
                    </Link>
                  </ListItemButton>
                </ListItem>
              </List>
              {/* <UserList>
                {martUsers.length > 0 ? (
                  <>
                    {martUsers.map((item) => (
                      // <ProductCard data={item} />
                      <li>
                        <Link key={item?.id} to={`/mart/user/${item?.id}/`}>
                          {item?.name} - ({item?.mart_items_count})
                        </Link>
                      </li>
                    ))}
                  </>
                ) : (
                  <p>
                    No users to show
                  </p>
                )}
                <li>
                  <Link to={`/mart/all/`}>
                    Queries for sale of all sellers
                  </Link>
                </li>
              </UserList> */}
            </>
          )}
        </Content>

      </MainBody>
      <Footer>
        All sales are final. Refunds are at the sole discretion of GimmeTheDog.com management.
      </Footer>
    </article>
  );
};

export default MartSellersPage;
