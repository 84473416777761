import axios from "axios";
import { URL } from "../Urls";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

export const getGeneralUseQueries = async (sportType) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.get(URL + `get_general_queries/${sportType}/`, config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
};

export const postGeneralUseQuery = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        return await axios.post(URL + "add_general_query/", data, config);
    } catch (error) {
        console.error(error)
    }
    return false
};

export const updateGeneralUseQuery = async (data, queryId) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        return await axios.post(URL + `update_general_query/${queryId}/`, data, config);
    } catch (error) {
        console.error(error)
    }
    return false
};


export const deleteGeneralUseQuery = async (queryId) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        return await axios.delete(URL + `delete_general_query/${queryId}/`, config);
    } catch (error) {
        console.error(error)
    }
    return false
};