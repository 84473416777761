import { Box, Checkbox, IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Link } from "react-router-dom";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  whiteSpace: 'nowrap'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MutedText = styled('p')(() => ({
  color: "#8e8e8e",
  margin: "0px",
  fontSize: ".8rem",
  fontStyle: "italic",
  fontWeight: "lighter",
}))

export default function QueriesTable({
  data,
  tabValue,
  handleQuerySelect,
  handleEditOpen,
  handleMonetizeOpen,
  handleRemoveFromSellOpen,
  canSell,
}) {
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const localHandleSelect = (e, queryId) => {
    handleQuerySelect(queryId, !e.target.checked);
  };

  const handleSellIcon = (query) => {
    if(query.is_for_sell) {
      handleRemoveFromSellOpen(query.id)
    } else {
      handleMonetizeOpen(query.id)
    }
  }

  const handleToggleResults = (queryId) => {
    console.log(queryId)
    const resultsRow = document.getElementById(`results-row-${queryId}`);
    resultsRow.classList.toggle("hidden");
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: "300px" }}>SDQL</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: "300px" }}>Description</StyledTableCell>
            <StyledTableCell>Is active</StyledTableCell>
            <StyledTableCell>Is disabled</StyledTableCell>
            <StyledTableCell>Next active date</StyledTableCell>
            <StyledTableCell>Created at</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((query) => (
            <>
              <StyledTableRow key={query.id} sx={{ verticalAlign: 'middle' }}>
                <StyledTableCell component="th" scope="row">
                  <Checkbox onChange={(e) => localHandleSelect(e, query.id)} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" sx={{ maxWidth: "300px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                  <MutedText>Date: {new Date().toLocaleDateString("en-US")}</MutedText>
                  <Link
                    to={`/${tabValue}?q=${encodeURIComponent(query.content)}`}
                    target="_blank"
                  >
                    {query.content}
                  </Link>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" sx={{ maxWidth: "300px", whiteSpace: "pre-wrap", }}>
                  {query.description}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {query?.active ? "True" : "False"}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {query?.disabled ? "True" : "False"}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {query.next_active_date ? new Date(Number(query.next_active_date) * 1000).toLocaleDateString("en-US") : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {new Date(Number(query.created_at) * 1000).toLocaleDateString("en-US")}
                </StyledTableCell>
                <StyledTableCell>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>

                    {query.result.processed && (
                      <Link
                        style={{
                          fontSize: '16px',
                          textAlign: 'center'
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          console.log("Link clicked")
                          handleToggleResults(query.id)
                        }}
                      >
                        <small>
                          Result
                          <br />
                          <span style={{
                            fontSize: '10px'
                          }}>Processed at: {new Date(query.result.processed_at).toLocaleDateString()} {new Date(query.result.processed_at).toLocaleTimeString()}</span>
                        </small>
                      </Link>
                    )}

                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditOpen(query.id)}
                    >
                      <EditIcon />
                    </IconButton>

                    {!canSell ? (
                      <Tooltip title="You dont have permission to sell.">
                        <IconButton aria-label="monetize">
                          <MonetizationOnIcon />
                        </IconButton>
                      </Tooltip>
                    ) : query.is_purchased ? (
                      <Tooltip title="This item was purchased.">
                        <IconButton aria-label="monetize">
                          <MonetizationOnIcon style={{ fill: "#d1d1d1" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        aria-label="monetize"
                        onClick={() => handleSellIcon(query)}
                      >
                        <MonetizationOnIcon style={{ fill: `${query.is_for_sell ? "green" : "gray"}` }} />
                      </IconButton>
                    )}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow id={`results-row-${query.id}`} className="hidden" sx={{ verticalAlign: 'middle' }}>
                {(query?.sport_type === "NFL" || query?.sport_type === "NCAAFB" || query?.sport_type === "CFL" || query?.sport_type === "NBA") ? (
                  <>
                    <StyledTableCell>
                      <strong>SU: </strong>
                      <span>{query.result?.SU}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>ATS: </strong>
                      <span>{query.result?.ATS}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>OU: </strong>
                      <span>{query.result?.OU}</span>
                    </StyledTableCell>
                  </>
                ) : query?.sport_type === "MLB" ? (
                  <>
                    <StyledTableCell>
                      <strong>SU: </strong>
                      <span>{query.result?.SU}</span>
                      <div className="my-2"></div>
                      <strong>SU ROI: </strong>
                      <span>{query.result?.SU_ROI}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>OU: </strong>
                      <span>{query.result?.OU}</span>
                      <div className="my-2"></div>
                      <strong>OU ROI: </strong>
                      <span>{query.result?.OU_ROI}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>RLF: </strong>
                      <span>{query.result?.RLF}</span>
                      <div className="my-2"></div>
                      <strong>RLF ROI: </strong>
                      <span>{query.result?.RLF_ROI}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>RLD: </strong>
                      <span>{query.result?.RLD}</span>
                      <div className="my-2"></div>
                      <strong>RLD ROI: </strong>
                      <span>{query.result?.RLD_ROI}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>t:team: </strong>
                      <span>
                        {query.result?.tteam?.length > 1 ? (
                          query.result?.tteam?.map(team => {
                            return <><br /> {team}</>
                          })
                        ) : <>{query.result?.tteam}</>}
                      </span>
                      <div className="my-2"></div>
                      <strong>o:team: </strong>
                      <span>
                        {query.result?.oteam?.length > 1 ? (
                          query.result?.oteam?.map(team => {
                            return <><br /> {team}</>
                          })
                        ) : <>{query.result?.oteam}</>}
                      </span>
                    </StyledTableCell>
                  </>
                ) : query?.sport_type === "NHL" ? (
                  <>
                    <StyledTableCell>
                      <strong>SU: </strong>
                      <span>{query.result?.SU}</span>
                      <div className="my-2"></div>
                      <strong>SU ROI: </strong>
                      <span>{query.result?.SU_ROI}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>OU: </strong>
                      <span>{query.result?.OU}</span>
                      <div className="my-2"></div>
                      <strong>OU ROI: </strong>
                      <span>{query.result?.OU_ROI}</span>
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <strong>SU: </strong>
                      <span>{query.result?.SU}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>OU: </strong>
                      <span>{query.result?.OU}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>SU ROI: </strong>
                      <span>{query.result?.SU_ROI}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>OU ROI: </strong>
                      <span>{query.result?.OU_ROI}</span>
                    </StyledTableCell> */}
                  </>
                ) : <></>}
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
