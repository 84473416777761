const docs = {
    "Shortcuts": [
        {name: "H", url: "H and (tA(points)+oA(points))<total"},
        {name: "A", url: "A and (tA(points)+oA(points))<total"},
        {name: "3DM", url: "date,week,team,o:team,3DM,3DA,4DM,4DA@season=2020 and week=1"},
        {name: "3DA", url: "date,week,team,o:team,3DM,3DA,4DM,4DA@season=2020 and week=2"},
        {name: "4DM", url: "date,week,team,o:team,3DM,3DA,4DM,4DA@season=2020 and week=3"},
        {name: "4DA", url: "date,week,team,o:team,3DM,3DA,4DM,4DA@season=2020 and week=4"},
    ],
    "Prefixes": [
        {name: "op: (opp's previous game)", url: "p:points>=38 and op:points>=38 and p:PO and op:PO"},
        {name: "p: (team's previous game)", url: "p:points>=38 and op:points>=38 and p:PO and op:PO"},
    ],
    "Parameters": [
        {name: "ats margin", url: "date,day,week,season,site,team,o:team,points,o:points,line,total,margin,ats margin@line<-20"},
        {name: "ats streak", url: "date,day,week,season,site,team,o:team,points,o:points,line,total,margin,ats streak@line<-20"},
        {name: "field goals", url: "field goals attempted as 'FGA'"},
    ]
}

export {docs};