import axios from "axios";
import { URL } from "../Urls";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

export const getUserData = async (userId=null) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.get(URL + `user/${userId ? `${userId}/` : ''}`, config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
};

export const getSellerData = async (sellerId) => {
    try {
        const res = await axios.get(URL + `seller/${sellerId}/`);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
};

export const updateUserProfile = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.patch(URL + `user/`, data, config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
};