import {
  Box,
  Button,
  Modal,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import React from "react";
import { deleteSavedQuery, postSavedQuery, updateSavedQuery } from "../../../Apis/QueryApi";

const DeleteSelectedQueriesModal = ({ fetchData, open, setOpen, selectedQueries }) => {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      queryIds: selectedQueries,
    };
    const res = await deleteSavedQuery(data);
    if (res) {
      fetchData();
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3 className="fs-3">Are you sure you want to permanently delete the selected SQDL queries?</h3>

        <div className="d-flex">
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={loading}
            onClick={(e) => handleSubmit()}
          >
            Yes
          </Button>
          <Button
            className="ms-2"
            variant="contained"
            type="button"
            disabled={loading}
            onClick={(e) => setOpen(false)}
          >
            No
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteSelectedQueriesModal;
