import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SportDocumentation = ({ sportType }) => {
    const [docs, setDocs] = useState({
        Shortcuts: [],
        Prefixes: [],
        Parameters: [],
    });

    useEffect(() => {
        let docsData = { docs: docs };
        switch (sportType) {
            case "NFL":
                docsData = require("../../documentation/NFL_docs");
                break;
            case "NCAAFB":
                docsData = require("../../documentation/NCAAFB_docs");
                break;
            case "NBA":
                docsData = require("../../documentation/NBA_docs");
                break;
            case "NHL":
                docsData = require("../../documentation/NHL_docs");
                break;
            case "MLB":
                docsData = require("../../documentation/MLB_docs");
                break;
            case "ATP":
                docsData = require("../../documentation/ATP_docs");
                break;
            case "CFL":
                docsData = require("../../documentation/CFL_docs");
                break;
            case "WNBA":
                docsData = require("../../documentation/WNBA_docs");
                break;
            case "NFLPRE":
                docsData = require("../../documentation/NFLPRE_docs");
                break;
            case "UFC":
                docsData = require("../../documentation/UFC_docs");
                break;
            case "UFCW":
                docsData = require("../../documentation/UFC_docs");
                break;
            default:
                break;
        }
        setDocs(docsData.docs);
    }, [sportType]);

    return (
        <>
            {Object.entries(docs).map(([header, parameters]) => (
                <div>
                    <h3>{header}</h3>
                    <ul class="list-group list-group-flush">
                        {parameters.map((param) => {
                            return (
                                <>
                                    <li
                                        onClick={() =>
                                            localStorage.setItem("docsTab", "set")
                                        }
                                        class="list-group-item"
                                    >
                                        <Link
                                            to={`/${sportType}?q=${encodeURIComponent(
                                                param.url
                                            )}`}
                                            target="_blank"
                                        >
                                            {param.name}
                                        </Link>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </div>
            ))}
        </>
    );
};

export default SportDocumentation;
