import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { getMartItem, getMartItemsCollection } from "../../Apis/MartApi";
import { approveOrder, initializeOrder } from "../../Apis/PaymentApi";
import Navbar from '../../Component/Navbar';
import { Body, DetailsList, Heading, LeftCont, MainCont, RightCont } from "./PaymentPage.styles";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PaymentPage = () => {
  const history = useHistory();
  const searchParams = useLocation().search;
  const isCollection = new URLSearchParams(searchParams).get("collection") === "true";

  const [martItem, setMartItem] = useState(null);

  const params = useParams();
  const martItemId = params.martItemId;

  const paypalButtonsRef = useRef();

  const getData = async () => {
    let data;

    if(isCollection) {
      data = await getMartItemsCollection(martItemId);
    } else {
      data = await getMartItem(martItemId);
    }

    if(data) {
      setMartItem(data);
    }
  }

  useEffect(() => {
    if(martItemId) {
      getData();
    }
  }, [martItemId])

  useEffect(() => {
    paypalButtonsRef.current.innerHTML = "";
    window.paypal.Buttons({
      style: {
        layout: 'vertical',
        color: 'gold',
        shape: 'pill',
        label: 'paypal'
      },

      createOrder: async (data, actions) => {
        paypalButtonsRef.current.classList.toggle("disabled", true);
        const orderId = await initializeOrder(martItemId, isCollection)
        paypalButtonsRef.current.classList.toggle("disabled", false)
        return String(orderId);
      },

      onApprove: async (data, actions) => {
        console.log(data, actions)
        const res = await approveOrder(data)
        if(res) {
          alert('Payment successful!');
          history.push("/saved-queries");
        } else {
          alert('Payment was not successful :-(');
        }
      },
    }).render('#paypal-buttons');
  }, [])


  return (
    <article>
      <Navbar />

      <Body>
        <MainCont>
          <LeftCont>
            <Heading>Product details</Heading>
            <DetailsList>
              <li><strong>ID: </strong>{martItem?.id}</li>
              <li><strong>Sport type: </strong>{isCollection ? "Collection" : martItem?.query?.sport_type}</li>
              <li><strong>Price: </strong>${martItem?.price}</li>
              <li><strong>Description: </strong>{martItem?.decription}</li>
            </DetailsList>
          </LeftCont>
          <RightCont>
            <div id="paypal-buttons" ref={paypalButtonsRef}></div>
          </RightCont>
        </MainCont>
      </Body>
    </article>
  )
}

export default PaymentPage
