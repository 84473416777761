import axios from "axios";
import { URL } from "../Urls";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

export const getSavedQueries = async (sport_type) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.get(URL + `get_saved_query/${sport_type}/`, config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
};

export const getSavedQuery = async (queryId) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.get(URL + `get_one_saved_query/${queryId}/`, config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
};

export const postSavedQuery = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    return await axios.post(URL + "save_query/", data, config);
    // try {
    //     if (res.status === 201) {
    //         return res.data
    //     }
    // } catch (error) {
    //     console.error(error)
    // }
    // return false
};

export const deleteSavedQuery = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    const res = await axios.post(URL + "delete_saved_query/", data, config);
    if (res.status === 200) {
        return res.data
    }
    return false
};

export const updateSavedQuery = async (data, queryId) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.patch(URL + `update_saved_query/${queryId}/`, data, config);
        if (res.status === 200) {
            return res.data
        }
    } catch (error) {
        console.error(error)
    }
    return false
};

export const processSavedQuery = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.post(URL + `process_saved_queries/`, data, config);
        if (res.status === 200) {
            return res.data
        }
    } catch (error) {
        console.error(error)
    }
    return false
};

export const deactivateSavedQuery = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.post(URL + `deactivate_saved_queries/`, data, config);
        if (res.status === 200) {
            return res.data
        }
    } catch (error) {
        console.error(error)
    }
    return false
};


// axios({
//     method: "post",
//     //url: "https://sdql-testapp.herokuapp.com/isAuthenticated/",
//     url: URL + "isAuthenticated/",
//     data: {
//       jwtCookie: props.jwtCookie,
//     },
//   }).then((res) => {
//     //console.log("APP: ", res)
//     if (res.data.message === "OK") {
//       props.doLogin();
//     }
// });
