import { DO_LOGIN, DO_LOGOUT, VISITED } from './MyTypes'

export const loginAction = (payload) => {
    return {
        type: DO_LOGIN,
        payload: payload,
    }
}

export const logoutAction = () => {
    return {
        type: DO_LOGOUT
    }
}

export const visitAction = () => {
    return {
        type: VISITED
    }
}