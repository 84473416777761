import { Box, Button, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteTodaysQuery } from "../../Apis/TodaysQueryApi";
import EditTodaysQueryModal from "../Modals/EditTodaysQueryModal";
import {
  Body,
  ConfigButtonsCont,
  Description,
  Heading,
  IconCont,
  MainSection,
  PointsText,
  SellerInfoSection,
  UseButton,
} from "./TodaysQueryCard.styles";
import deleteIcon from "../../assets/icons/times.svg";
import editIcon from "../../assets/icons/edit.svg";
import { visitAction } from "../../redux/MyActions";
import { connect } from "react-redux";

const TodaysQueryCard = ({ data, fetchData, ...props }) => {
  const history = useHistory();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUse = async () => {
    history.push(`/${data.sport_type}/?q=${encodeURIComponent(data.content)}`);
  };

  const handleOpenDeleteModal = async () => {
    setOpenDeleteModal(true);
  };

  const handleOpenEditModal = async () => {
    setOpenEditModal(true);
  };

  const handleDeleteQuery = async () => {
    setLoading(true);
    const res = await deleteTodaysQuery(data.id);
    if (res.status === 202) {
      fetchData();
    }
    setLoading(false);
  };

  return (
    <>
      <Body>
        {props.userData.isStaff && (
          <ConfigButtonsCont>
            <IconCont onClick={() => handleOpenDeleteModal()}>
              <img src={deleteIcon} alt="Delete" />
            </IconCont>
            <IconCont onClick={() => handleOpenEditModal()}>
              <img src={editIcon} alt="Edit" />
            </IconCont>
          </ConfigButtonsCont>
        )}
        <MainSection>
          <Heading>
            <span>Description: </span>
          </Heading>
          <Description>{data?.description}</Description>
        </MainSection>

        <div style={{ textAlign: "center" }}>
          <SellerInfoSection>
            <PointsText>
              <span>Type: </span>
              <small>{data.sport_type}</small>
            </PointsText>
          </SellerInfoSection>

          <UseButton className="btn" onClick={() => handleUse()}>
            Use Query
          </UseButton>
        </div>
      </Body>

      <EditTodaysQueryModal
        fetchData={fetchData}
        open={openEditModal}
        setOpen={setOpenEditModal}
        data={data}
      />

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h3 className="fs-3 mb-3">Do you really wanna delete this query?</h3>

          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={loading}
            onClick={() => handleDeleteQuery()}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: { ...state },
    isAuthenticated: state.isAuthenticated,
    firstVisit: state.firstVisit,
    jwtCookie: state.jwtCookie,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAuthenticate: () => dispatch(visitAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TodaysQueryCard);
