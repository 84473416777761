import axios from "axios";
import { URL } from "../Urls";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

export const createPaymentSession = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.post(URL + `create_payment_session/`, data, config);
        console.log(res);
        if (res.status === 201) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return null;
};

export const initializeOrder = async (martItemId, isCollection=false) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.post(URL + `order/initialize/${martItemId}/?collection=${isCollection}`, {}, config);
        if (res.status === 201) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return null;
};

export const approveOrder = async (data) => {
    const config = {
        headers: {
            Authorization: cookies.get('jwt'),
        }
    }
    try {
        const res = await axios.post(URL + `order/approve/`, data, config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        console.error(error)
    }
    return null;
};