const docs = {
    "Shortcuts": [
        {name: "A - away - Ex: Away team favored by more than 2 TDs", url: "A and line<-14"},
        {name: "H - home - Ex: Home dogs of a home dog win that went over the total and the total is over 43", url: "HD and p:HDWO and total>43"},
        {name: "F - favorite - Ex: Away favorites after game 3 who have yet to win a game", url: "wins=0 and AF and game number>3"},
        {name: "D - dog - Ex: Home dogs that beat the opponent 4-straight times", url: "HD and and P:W and PP:W and PPP:W and PPPP:W "},
        {name: "O - over - Ex: Favorites in a division game off 2-straigt overs where the total is the lowest of the last 3", url: "p:O and p2:O and total<p:total and total<p2:total and DIV and F"},
        {name: "U - under - Ex: teams off a home under loss as the favorite and the total is over 50", url: "p:HULF and p:total>50"},
        {name: "W - win - Ex: Each team's winning percentage in the playoffs", url: "tA(W)@team and PO"},
        {name: "L - loss - Ex: Team's win and loss records", url: "tS(W),tS(L)@team and season=2021"},
        {name: "C - conference - Ex: Teams off a home favorite over win in a conference game and the team is now getting more then 10 points", url: "p:HFOWC and line>10"},
        {name: "3DA - 3rd down attempts - Ex: Teams off a game with over 20 3rd down attempts and few than 2 4th down tries", url: "p:3DA>20 and p:4DA<2"},
        {name: "3DF - 3rd down failures - Ex: Off home win and failed on over 12 3rd downs and now are away", url: "p:3DF>12 and p:W and p:H and A"},
        {name: "3DM - 3rd downs made - Ex: Team won as home dog while making 3 more 3rd downs then their last opponent", url: "p:3DM>po:3DM+5 and p:HDW "},
        {name: "3DP - 3rd down percentage - Ex: Last game, team lost and made over 50% of 3rd down tries while opp was under 30%", url: "p:3DP>50 and po:3DP<30 and p:L"},
        {name: "4DA - 4th down attempts - Ex: Team made over 2 4th down tries and won at a home dog", url: "p:4DA>2 and p:HDW"},
        {name: "4DF - 4th down failures - Ex: Show season totals of 4th down attempts, made, and failures", url: "R(team),tS(4DA),tS(4DM),tS(4DF)@team and season=2021"},
        {name: "4DM - 4th downs made - Ex: Team off a W when they made over 2 4th down conversions", url: "p:4DM>2 and p:W"},
        {name: "4DP - 4th down percentage - Ex: Team off game where they tried over 2 4th downs and converted all", url: "p:4DA>2 and p:4DP=100"},
        {name: "ATSL - against the spread loss - Ex: Results for team that have NOT covered 6-straight and now are home dogs", url: "p:ATSL and p2:ATSL and p3:ATSL and p4:ATSL and p5:ATSL and p6:ATSL and HD"},
        {name: "ATSW - against the spread win - Ex: Last meeting, team covered at home but lost as a dog and now are away dogs", url: "P:ATSW and P:HDL and AD"},
        {name: "ATSWP - against the spread win percentage - Ex: After week 10, teams the cover more than not and more than 10 point away dogs", url: "ATSWP>50 and week>10 and line>10 and A and DIV"},
        {name: "AWP - away win percentage - Ex: Chiefs' away winning percentage", url: "date,team,o:team,site,tS(AW)*1,tS(AL)*1,AWP@team=Chiefs and season=2021"},
        // {name: "BG - bowl game - Ex: ", url: ""},
        {name: "BYE - Away favorites off a bye in a non-division game - Ex: ", url: "p:BYE and AF and NDIV"},
        {name: "COMP - completions - Ex: Home favs off a win of less than 3 points where they covered the spread", url: "p:COMP>30 and p:margin<3 and p:ATSW and HF"},
        {name: "CP - completion percentage - Ex: Teams off a home game with an 80% completion percentage going into a division home game.", url: "p:CP>80 and DIV and H"},
        {name: "DIV - division game - Ex: Teams in the NFC South in division matchups as home dogs getting more than a TD", url: "DIV and division=NFC South and H and line>7"},
        {name: "DTD - defensive touchdowns - Ex: Teams off a game with at at least one defensive and kick return TD going into a divisional game as favorites", url: "p:DTD!=p:NOTD and p:DTD>0 and DIV and F"},
        {name: "FD - first downs - Ex: Team that had 20+ more first downs in the last meeting", url: "P:FD>Po:FD+20"},
        {name: "FDP - first downs percentage - Ex: Teams of a Sunday Night game where the made a 1st down on 45% of the plays", url: "p:FDP>45 and p:SNF"},
        // {name: "GTGA - Ex: ", url: ""},
        // {name: "GTGM - Ex: ", url: ""},
        {name: "HWP - home win percentage - Ex: Teams going into a Sunday night home game without a win", url: "HWP=0 and SNF and H"},
        {name: "INC - incomplete pass - Ex: Teams off  game where they had more penalties than incomplete passes in a loss that went over the today and now are away", url: "p:PEN>p:INC and p:OL and A"},
        {name: "INT - interceptions - Ex: Teams off a game with over 5 interceptions", url: "po:interceptions>5"},
        {name: "M1 - margin after quarter 1 - Ex: Teams off a game where they were ahead by 14 or more after the 1st quarter and lost on the road", url: "p:M1>=14 and p:AL"},
        {name: "M2 - margin at the half - Ex: Teams down by 17 at the half last game and came back to win at home", url: "p:M2<=-17 and p:HW"},
        {name: "M3 - margin after quarter 3 - Ex: Teams off an away win where they came back being down double-digits entering the 4th quarter and now away", url: "p:M3<=-10 and p:AW and A"},
        {name: "MNF - Monday Night Football - Ex: Teams from the Pacific time zone playing away against an Eastern time zone team on Monday Night", url: "A and time zone=P and o:time zone=E and MNF"},
        {name: "NB - team not off a bye - Ex: Teams not off a bye against a team off a bye", url: "team and p:NB and op:BYE"},
        {name: "NDIV - non-division game - Ex: Away teams playing a non-division team sandwiched between division game last week and next week and next week is also away", url: "p:DIV and NDIV and n:DIV and season>2015 and A and n:A"},
        {name: "NOTD - non-offensive touchdowns - Ex: Teams off a game with 3 or more defensive TDs", url: "p:NOTD>2"},
        {name: "OFPL - offensive plays - Ex: Last meeting, teams that had 30 or more offensive plays and lost", url: "P:OFPL>Po:OFPL+30 and P:L"},
        {name: "OT - overtime - Ex: Last meeting was a loss this season at home that went to OT against divisional opp an the team is now the favorite", url: "P:OT and P:L and P:H and DIV and P:season=season and F"},
        {name: "P1 - team's points in 1st quarter - Ex: Teams off a game where 1Q was the highest scoring quarter and the team rushed for over 150 yards in a home loss that went under the total", url: "p:P1>p:P2 and p:P1>p:P2 and p:P1>p:P3 and p:P1>p:P4 and p:RY>150 and p:UL and H"},
        {name: "P2 - team's points in 2nd quarter - Ex: Team off a game with no 1st half point and then went on to win a game that went over the total", url: "p:P1+P2=0 and p:OW"},
        {name: "P3 - team's points in 3rd quarter - Ex: Teams off a game with no 2nd half points in a game that they won and was over the total", url: "p:P3+p:P4=0 and p:OW"},
        {name: "P4 - team's points in 4th quarter - Ex: Teams off a home game where the outscored the opp by at least 2 TD in the 4th Q and had over 300 passing yards", url: "p:P4>po:P4+14 and p:PY>300 and p:H"},
        {name: "PEN - number of penalties - Ex: Teams off a win, where they covered the spread and had no penalties", url: "p:PEN=0 and p:W and p:ATSW"},
        {name: "PENFD - penalty first downs - Ex: Teams off an away game where the got over 5 first down via penalty and lost", url: "p:PENFD>5 and p:AL"},
        {name: "PENY - penalty yards - Ex: Teams that average over 100 penalty yards per game now playing a divisional game", url: "tA(PENY)>100 and DIV"},
        {name: "PFD - passing first downs - Ex: Teams that have 75 more passing first downs than they allowed after week 10", url: "tS(PFD)>oS(PFD)+75 and week>10"},
        {name: "PO - playoffs - Ex: Playoff teams off a playoff win as the favorite where they did not cover the spread and are favorites again", url: "p:PO and PO and p:F and p:ATSL and F"},
        {name: "PY - passing yards - Ex: Team in a divisional game after 3-straight 300+ passing yard games", url: "p:PY>300 and p2:PY>300 and p3:PY>300 and DIV"},
        {name: "RY - rushing yards - Ex: Teams that lost 2 straight games and rushed for over 150 in both", url: "p:RY>150 and p2:RY>150 and tS(W,N=2)=0"},
        {name: "REG - regular season game - Ex: Home dogs getting more than a TD in a regular season night non-division games", url: "REG and start time>2000 and H and NDIV and line>7"},
        {name: "RFD - rushing first down - Ex: Home favorites that are averaging over 10 rushing fisrt downs per game", url: "tA(RFD)>10 and HF"},
        {name: "RTD - rushing touchdowns - Ex: Away favorites that have 20 more rushing TDs than passing TD after game 10", url: "tS(RTD)>tS(PTD)+20 and game number>10 and AF"},
        {name: "RY - rushing yards - Ex: Teams off a home loss with over 100 penalty yards, fewer than 100 rushing yard, under 200 passing yards and are now away", url: "p:PENY>100 and p:RY<100 and p:PY<200 and p:HL and A"},
        // {name: "RZA - Ex: ", url: ""},
        // {name: "RZF - Ex: ", url: ""},
        // {name: "RZM - Ex: ", url: ""},
        {name: "S1 - points at end of 1st quarter - Ex: Teams off a away loss with no points in the 1st quarter and over 14 points in the 2nd quarter", url: "p:S1=0 and p:S2>14 and p:AL"},
        {name: "S2 - points at the half - Ex: Teams off an away loss where neither team scored in the 1st half", url: "p:S2=0 and po:S2=0 and p:AL"},
        {name: "S3 - points at end of 3rd quarter - Ex: Teams off a home game where they were scoreless thru 3 quarters", url: "p:S3=0 and p:H"},
        {name: "SBL - Super Bowl loser - Ex: Super Bowl loser in week 1 and away", url: "week=1 and SBL and A"},
        {name: "SBW - Super Bowl winner - Ex: Super Bowl winners in week 1 and H", url: "week=1 and SBW and H"},
        {name: "SIQ - scored in quarters - Ex: Teams of an OT win where both teams scored in all 4 quarters", url: "p:SIQ=4 and po:SIQ=4 and p:OT and p:W"},
        {name: "SNF - Sunday Night Football - Ex: Home team under .500 against a team that's over .500 on Sunday night ", url: "SNF and WP<50 and o:WP>50 and H"},
        // {name: "STDRAPG - Ex: ", url: ""},
    ],
    "Parameters": [
        {name: "ats margin - Ex: Teams off a game where they covered by over 7 TDs and now are dogs", url: "p:ats margin>35 and p:D"},
        {name: "ats streak - Ex: Home dogs that have covered 5 in a row", url: "ats streak>5 and HD"},
        {name: "attendance - Ex: Favorites in games with over 85,000 in attendance", url: "attendance>85000 and F"},
        // {name: "average drive time - Ex: ", url: "p:average drive time<2 and p:W"},
        {name: "average drive yards - Ex: Jets average drive data", url: "date,team,o:team,average drive time,average drive yards@team=Jets and season=2021"},
        // {name: "average net punt yards - Ex: ", url: ""},
        // {name: "average punt yards - Ex: ", url: "date,team,o:team,average punt yards,average net punt yards@team=Raiders and season=2019"},
        // {name: "away site streak - Ex: ", url: ""},
        {name: "bad throw percentage - Ex: Away teams off 2-straight games where over 25% of the passes were bad throws", url: "p:bad throw percentage>.25 and pp:bad throw percentage>.25 and A"},
        {name: "bad throws - Ex: Home dogs off a game with 10 bad throws", url: "p:bad throws>10 and HD"},
        {name: "biggest lead (BL) - Ex: Away favs off a game where they lead by over 17 and lost at home", url: "p:biggest lead>17 and p:HL and AF "},
        {name: "blitzes - Ex: double-digit home favs who average double-digit blitzes per game", url: "tA(blitzes)>9 and H and line<-9.5 "},
        {name: "blocked extra points - Ex: Team's blocked kick numbers", url: "tS(blocked extra points),tS(blocked field goals),tS(blocked punts)@team and season=2020"},
        {name: "blocked field goals - Ex: Teams off a game where they blocked multiple kicks", url: "p:blocked field goals>1"},
        {name: "blocked punts - Ex: Teams off a game where they blocks a punt and won as a dog", url: "p:blocked punts>0 and p:DW"},
        {name: "coach - Ex: Team in the 1st game with a new head coach mid season", url: "coach!=p:coach and season=p:season"},
        {name: "completed air yards - Ex: Team off a game where the QB had 275 at the spot the ball was caught", url: "p:completed air yards>275"},
        {name: "completions (comp) - Ex: Home team favored by more than 5 who average over 30 completions/game", url: "tA(completions)>30 and H and line<-5"},
        {name: "conference (C) - Ex: Home favorite AFC team on Sunday Night", url: "conference=AFC and SNF and HF"},
        {name: "data date - Ex: The last date the data was updated for week 1", url: "team,o:team,data date,data time@season=2021 and week=1"},
        {name: "data time - Ex: The last date the data was updated for week 1", url: "team,o:team,data date,data time@season=2021 and week=1"},
        {name: "date - Ex: How the home teams did on 9/12/2021", url: "date=20210912 and H"},
        {name: "day - Ex: Home dogs in Saturday playoff games ", url: "day=Saturday and HD and playoffs=1"},
        // {name: "def blocked kick td - Ex: ", url: ""},
        {name: "def fumbles recovered (2021 on) - Ex: Teams who recoverd more that 2 fumbles", url: "date,team,def fumbles recovered@def fumbles recovered>2"},
        {name: "def interceptions (2021 on - Ex: Team's playing a team that had 3 or more picks in the last meeting", url: "P:def interceptions>2"},
        // {name: "defensive 2pt conversion return - Ex: ", url: ""},
        {name: "division (DIV) - Ex: Colts record against the NFC South", url: "team=Colts and o:division=NFC South"},
        {name: "dpa (delta points allowed) - Ex: Favs off 2-straight game where they allowed 14 points over the opponents team total", url: "p:dpa>14 and pp:dpa>14 and F"},
        {name: "dps (delta points scored) - Ex: Teams who score 3 touchdowns over their team total and lost", url: "p:dps>21 and p:L"},
        {name: "drives - Ex: Team's average drives per game", url: "R(team),tA(drives)@team and season=2021"},
        {name: "drop percentage - Ex: Teams off a win in which they dropped 10% of the passes thrown", url: "p:drop percentage>.1 and p:W"},
        {name: "drops - Ex: Team's off a game with over 5 drops", url: "p:drops>5 "},
        // {name: "extra point return - Ex: ", url: ""},
        {name: "field goal drives - Ex: Home teams after 3-striaght game where they had more FGs that TDs", url: "p:field goal drives>p:touchdown drives and pp:field goal drives>pp:touchdown drives and  ppp:field goal drives>ppp:touchdown drives and H"},
        {name: "field goals - Ex: Team off a game with 6 or more FGs", url: "p:field goals>5"},
        {name: "field goals attempted - Ex: Team's off a game with 3 missed FGs", url: "p:field goals attempted-p:field goals>3"},
        {name: "first downs (FD) - Ex: Teams off a loss where they had over 30 first downs", url: "p:first downs>30 and p:L"},
        {name: "fourth downs attempted (4DA) - Ex: Teams off a game where they made over 3 fourth down attempts", url: "p:fourth downs attempted>3 and p:fourth downs made>3"},
        {name: "fourth downs made (4DM) - Ex: Teams off a game where the made 4 or more 4th down attempts with a failure", url: "p:fourth downs attempted>3 and p:fourth downs made>3"},
        {name: "full name - Ex: Team full names", url: "R(team),R(full name)@team"},
        {name: "fumble return touchdowns - Ex: Teams off a game with at least 2 scoop and scores", url: "p:fumble return touchdowns>1"},
        {name: "fumble return yards - Ex: Teams off a game where they had over 50 fumble return yards", url: "p:fumble return yards>50"},
        {name: "fumbles - Ex: Home favorites off a game wiht over 5 fumbles", url: "p:fumbles>5 and HF"},
        {name: "fumbles lost - Ex: Team off an away win in which they lost at least 3 fumbles", url: "p:fumbles lost>2 and p:AW"},
        {name: "game number - Ex: Winless teams after game 8", url: "game number>8 and tS(W)=0"},
        {name: "game type - Ex: Records of the dog in Super Bowls", url: "game type=SB and D"},
        {name: "goal to go attempted - Ex: Teams off a game with 3 or more goal to go attempts without succeeding", url: "p:goal to go attempted>2 and p:goal to go made=0"},
        {name: "goal to go made - Ex: Teams of a game as the away fav where the has over 3 goal to go attempts and make them all", url: "p:goal to go attempted>3 and p:goal to go made=p:goal to go attempted and p:AF"},
        {name: "gross passing yards - Ex: Teams with over 500 gross passing yards (no sack yards)", url: "gross passing yards>500"},
        // {name: "home site streak - Ex: ", url: ""},
        {name: "hurries - Ex: Team off a game where their D forced over 10 hurries", url: "po:hurries>10"},
        {name: "intended pass yards - Ex: Teams off a game with under 150 intended pass yards and won by more that 2 TDs", url: "p:intended pass yards<150 and p:margin>14"},
        {name: "intended pass yards per pass - Ex: Teams off a game with an average of under 5 intended pass yards per play and won by more than 10", url: "p:intended pass yards per pass<5 and p:margin>10"},
        {name: "interception touchdowns - Ex: Teams off a dog win where that had at least 2 pick 6s and are a dog again", url: "P:interception touchdowns>1 and P:DW and D"},
        {name: "interceptions - Ex: Team playing a division opponent where they had 5 or more picks the last meeting", url: "P:interceptions>=5 and DIV"},
        {name: "kicking extra points - Ex: Team that had at least 1 TD and did not make an extra point", url: "p:kicking extra points attempted>1 and p:kicking extra points=0"},
        // {name: "kicking extra points attempted - Ex: ", url: ""},
        // {name: "kickoff return average - Ex: ", url: ""},
        // {name: "kickoff return long - Ex: ", url: ""},
        // {name: "kickoff return touchdowns - Ex: ", url: ""},
        // {name: "kickoff return yards - Ex: ", url: ""},
        // {name: "kickoff returns - Ex: ", url: ""},
        // {name: "kickoffs - Ex: ", url: ""},
        // {name: "kickoffs for touchback - Ex: ", url: ""},
        // {name: "kickoffs in end zone - Ex: ", url: ""},
        {name: "lead changes - Ex: Favs off an away dog win where there were over 4 lead changes", url: "p:lead changes>4 and p:ADW and F"},
        {name: "line - Ex: Teams off a loss where they were getting over a TD and there were over 4 lead changes", url: "p:lead changes>4 and p:line>7 and p:L"},
        {name: "longest drive - Ex: Teams off a win as a dog that went under the total and their longest drive was under 70 yards", url: "p:longest drive<70 and p:UDW "},
        // {name: "longest punt - Ex: ", url: ""},
        {name: "losses - Ex: Playoff teams with more losses than wins", url: "losses>wins and PO"},
        {name: "margin - Ex: Dogs off a win by more than 3 TDs as a dog", url: "p:D and p:margin>21 and D"},
        {name: "margin after the first (M1) - Ex: Teams of a game where they we up by over 10 after the 1st and lost at home", url: "p:margin after the first>10 and p:HL"},
        {name: "margin at the half (M2) - Ex: Team off a win that went under the today where the team was down by over 10 at halftime", url: "p:margin at the half<-10 and p:UW"},
        {name: "margin after the third (M3) - Ex: Teams up by a TD going into the 4th quarter and allowed over 400 passing yards", url: "p:margin after the third>7 and p:L and po:passing yards>400"},
        // {name: "matchup losses - Ex: ", url: ""},
        // {name: "matchup wins - Ex: ", url: ""},
        {name: "money line - Ex: Home teams getting over 5-1 straight up", url: "H and money line>500"},
        {name: "month - Ex: How home dogs in division games that average over 125 rushing yards in December", url: "month=12 and tA(rushing yards)>125 and HD and DIV"},
        // {name: "net passing yards - Ex: ", url: ""},
        {name: "officials - Ex: Games where Tony Corrente is an official", url: "'Tony Corrente' in officials and HD"},
        // {name: "open line - Ex: ", url: ""},
        // {name: "open odds - Ex: ", url: ""},
        // {name: "open total - Ex: ", url: ""},
        // {name: "open total odds - Ex: ", url: ""},
        {name: "opening drive - Ex: How double-digit home favs do in division games where they recieved the opening kick", url: "opening drive=1 and line<-=10 and H and DIV"},
        {name: "opponents - Ex: How AFC East teams do as home dogs after game 12 having already played the Pats", url: "'Patriots' in opponents and DIV and division=AFC East and and HD and game number>12"},
        {name: "ou margin - Ex: Teams off a game that was at least 4 TDs over the total and are now dogs in a game with a total under 40 ", url: "p:ou margin>28 and total<40 and D"},
        {name: "ou streak - Ex: Teams that went over the total 6 straight time and the total is over 45", url: "ou streak>6 and total>45"},
        // {name: "ou_margin - Ex: ", url: ""},
        {name: "overtime (OT) - Ex: Teams of 2-straignt OT losses", url: "p:overtime=1 and p:L and pp:overtime=1 and pp:L"},
        {name: "passes - Ex: Teams off a game where they passed over 4 times more than rushes and now are home dogs", url: "p:passes>p:rushes*4 and HD"},
        {name: "passing first downs (PFD) - Ex: Teams off an away dog win the was over the total with fewer passing 1st downs than rushing 1st downs and now away", url: "p:passing first downs<p:rushing first downs and p:ADWO and A"},
        {name: "passing first downs per pass - Ex: Team average first downs per pass", url: "tA(passing first downs per pass)@team and season=2021"},
        {name: "passing touchdowns - Ex: Team off a game with 6 or more passing TDs", url: "p:passing touchdowns>5"},
        {name: "passing yards (PY) - Ex: The Jets passing numbers", url: "passing yards,gross passing yards,o:sack yards@team=Jets and season=2021"},
        {name: "penalties (PEN) - Ex: Away favs off a game with 10 more rushing 1st downs than penalties", url: "p:penalties+10<p:rushing first downs and AF"},
        {name: "penalty first downs (PENFD) - Ex: Teams off a game with more penalty 1st downs than passing 1st downs", url: "p:penalty first downs>p:passing first downs"},
        {name: "penalty yards (PENY) - Ex: Away team of a home win as a dog in which they had more penalty yards than rushing yards", url: "p:penalty yards>p:rushing yards and p:HDW and A"},
        {name: "playoffs (PO) - Ex: Dogs with a higher winning % than opponent in the playoffs", url: "WP>o:WP and D and playoffs=1"},
        {name: "plays - Ex: Team's average plays and plays allowed per game", url: "tA(plays),oA(plays)@team and season=2021"},
        {name: "points - Ex: Favorites in a division game coming off being shutout", url: "p:points=0 and p:F and p:division=po:division"},
        // {name: "punt return average - Ex: ", url: ""},
        {name: "punt return long - Ex: Teams off a win by over 28 with no punt return yard that was under the total", url: "p:punt return long=0 and p:U and p:margin<-28"},
        {name: "punt return touchdowns - Ex: Teams off a loss where they had a punt return for a TD", url: "p:punt return touchdowns>0 and p:L"},
        {name: "punt return yards - Ex: Teams off a win in which they had more punt return yards than passing yards", url: "p:punt return yards>p:passing yards and p:W" },
        {name: "punt returns - Ex: Team off a game where returned over 7 punts", url: "p:punt returns>7"},
        {name: "punt yards - Ex: Teams off a loss they went under the total and did not punt", url: "p:punt yards=0 and p:UL "},
        {name: "punts - Ex: Teams off loss where they punted 10 or more times in an over game", url: "p:punts>9 and p:LO"},
        {name: "qb hits - Ex: Teams of a game as a dog where the qb hits was over 7", url: "p:qb hits>7 and p:D"},
        {name: "qb pressures - Ex: In the last meeting, the team had over 12 QB pressures and lost", url: "P:qb pressures>12 and P:L"},
        {name: "qb pressures percentage - Ex: Chargers qb pressure data", url: "date,o:team,qb pressures percentage,o:qb pressures percentage,ou margin@team=Chargers and season=2021"},
        {name: "quarter scores - Ex: Quarter scores for the Bills for the season", url: "date,team,quarter scores,o:team,quarter scores@team=Bills and season=2021"},
        {name: "receiving broken tackles - Ex: In a home loss last meeting earlier the same season, team that had more receiveing broken tackles then punts", url: "P:receiving broken tackles>P:punts and P:HL and P:season=season"},
        // {name: "red zones attempted - Ex: ", url: ""},
        // {name: "red zones made - Ex: ", url: ""},
        // {name: "regular season wins line - Ex: ", url: ""},
        {name: "rest - Ex: Away dogs getting more than a TD with over 10 days rest against a team on short rest", url: "rest>10 and o:rest<6 and A and line>7"},
        {name: "return yards - Ex: Teams off an away win where they had more return yards than passing and rushing yards combined", url: "p:return yards>(p:passing yards+p:rushing yards) and p:AW"},
        {name: "RSWL (Regular season win line) - Ex: Home favs in week win who regular season win line is less that total wins last year", url: "tpS(W@REG)-1>RSWL and week=1 and HF"},
        {name: "rushes - Ex: Team off a home blowout win by more that 35 with more rushes than passes and are now away", url: "p:rushes>p:passes and p:margin>35 and p:H and A"},
        {name: "rushes for a loss - Ex: Teams off a win where they had over 7 rushes for a loss", url: "p:rushes for a loss>7 and p:W"},
        {name: "rushing broken tackles - Ex: Teams off an away win under the total with over 12 broken tackles", url: "rushing broken tackles>12 and p:AWU"},
        {name: "rushing first downs (RFD) - Ex: Teams averaging over 10 1st downs per game playing a team with a better winning percentage", url: "tA(rushing first downs)>10 and WP<o:WP"},
        {name: "rushing touchdowns (RTD - Ex: Teams off a home loss where they had at least 3 more rush TDs than pass TDs ", url: "p:rushing touchdowns>po:rushing touchdowns+2 and p:HL"},
        {name: "rushing yards (RY) - Ex: Teams facing a opp they beat last time earlier the same season and rushed for over 200 yards", url: "P:rushing yards>200 and P:season=season and P:W"},
        {name: "rushing yards after contact - Ex: Teams off an away win over the total will over 100 rushing yards made after contact", url: "p:rushing yards after contact>100 and p:AWO"},
        {name: "rushing yards before contact - Ex: Home teams off a home win with over 100 rushing yards before contact ", url: "p:rushing yards before contact>100 and p:HW and H"},
        {name: "rushing yards lost - Ex: Teams off a game with more rushing yards lost that rushing yards", url: "p:rushing yards lost>p:rushing yards"},
        {name: "sack yards (SY) - Ex: Home teams off a a away dog over wins were they were not sacked", url: "p:sack yards=0 and p:ADWO and H"},
        {name: "sacks - Ex: Teams off a game where the punted over 5 times and were sacked 5 or fewer times", url: "p:punts<po:sacks and p:punts>5"},
        {name: "safeties - Ex: Teams off a game with multiple safties", url: "p:safeties>1"},
        {name: "scored first - Ex: In the last meeting this season in a division game where the team scored first and lost", url: "P:scored first>10 and P:L and P:season=season and DIV"},
        {name: "scrambles - Ex: Teams off a home win in which they had 5 or more scrambles", url: "p:scrambles>5 and p:HW"},
        {name: "season - Ex: Away dogs in the 2nd meeting of the season after being crushed at home by over 21", url: "DIV and P:season=season and P:margin>21 and AD and REG"},
        {name: "shortest drive - Ex: Count of team's negative yardage drives for the season", url: "tS(shortest drive<0)@team and season=2021 and REG"},
        {name: "site - Ex: Teams off 3-straight road wins", url: "site=away and p:site=away and pp:site=away and tS(W,N=3)=3"},
        // {name: "site streak - Ex: ", url: ""},
        // {name: "snf - Ex: ", url: ""},
        {name: "special teams td - Ex: Teams averaging a special teams TD in 10% of their games", url: "tA(special teams td)>.1"},
        {name: "stadium - Ex: Team's stadium names", url: "R(team),R(stadium)@team and season=2022"},
        {name: "start time - Ex: Teams record in night games", url: "R(team) as 'Team',tS(W) as 'Night Wins',tS(L) as 'Night Losses',R(tS(W)/(tS(W)+tS(L))) as 'WP in Night Games'@team and start time>2000"},
        {name: "streak - Ex: Away favs on a 8+ game win streak", url: "streak>7 and AF"},
        // {name: "streak date - Ex: ", url: ""},
        {name: "surface - Ex: How the Vikings do on grass as away favorites", url: "surface=grass and team=Vikings and AF"},
        {name: "team - Ex: How the Viking do against the Jets", url: "team=Vikings and o:team=Jets"},
        {name: "temperature - Ex: Away favorites in games under 40 degrees", url: "temperature<40 and AF"},
        {name: "third downs attempted (3DA) - Ex: Teams off a game with over 10 3rd down failures without a success", url: "p:third downs attempted>10 and p:third downs made=0"},
        {name: "third downs made (3DM) - Ex: Team off a game with over a dozen 3rd downs made", url: "p:third downs made>12"},
        {name: "time of possession (TOP) - Ex: Home teams off a home win in which they had over 40 minutes of possession", url: "p:time of possession/60>40 and p:HW  and H"},
        {name: "time zone - Ex: East coast team as favorites in the 1st of 2 games on the west coast", url: "time zone=E and o:time zone=P and A and no:time zone=P and F"},
        {name: "times tied - Ex: Teams off a loss that was tied 4 or more times", url: "p:times tied>3 and p:L"},
        // {name: "total yards - Ex: ", url: ""},
        {name: "touchdown drives - Ex: ", url: "p:touchdown drives>5 and p:L "},
        {name: "touchdowns (TD) - Ex: ", url: "p:touchdowns>6 and p:A"},
        {name: "turnover margin (TOM) - Ex: Dogs off a dog win in which they had won the turnover battle by at least 3", url: "p:turnover margin>2 and p:DW and D"},
        {name: "turnovers (TO) - Ex: Teams off a home favorite lost where they turned it over at least 7 times.", url: "p:turnovers>6 and p:HFL"},
        // {name: "two point conversion attempts - Ex: ", url: ""},
        // {name: "two point conversions - Ex: ", url: ""},
        // {name: "two point conversions attempted - Ex: ", url: ""},
        {name: "week - Ex: Home dogs getting at least a TD in week 1 ", url: "week=1 and H and line>7"},
        {name: "wind - Ex: Favorites in games with over 20 mph winds", url: "wind>20 and F"},
        {name: "wins - Ex: Teams with no wins after game 10", url: "wins=0 and week>10"},
        {name: "yards after catch - Ex: Teams off an ADW in which they had over 150 yards over after the catch", url: "p:yards after catch>150 and p:ADW"},
        {name: "yards after catch per completion - Ex: After playing earlier the same season, away dogs that averaged over 6 yards after catch", url: "P:yards after catch per completion>6 and P:season=season and AD"},
    ],
    "Prefixes": [
        {name: "op: (opp's previous game) - Ex. Dogs off a win, opp off loss. Both teams score o40+", url: "p:points>=40 and op:points>=40 and p:W and op:L and D"},
        {name: "p: (team's previous game) - Ex. Teams off a game where they scored 50 and lost", url: "p:L and p:points>50"},
        {name: "t: - team (if omitted, assumed) - Ex. Packers at home", url: "t:team=Packers and t:site=home"},
        {name: "o: - opponent - Ex. Dallas as more than a TD dog to G-men", url: "team=Cowboys and o:team=Giants and o:line<-7"},
        {name: "p: - previous game - Ex. Home dogs off a game without a turn over against a team that had 5+ turnovers", url: "p:TO=0 and op:TO>4 and HD"},
        {name: "op: - opponent's previous game - Ex. Team favored by 7 for fewer in a bowl game where both teams scored 40_ last game", url: "p:points>=40 and op:points>=40 and BG and line>-7 and F"},
        {name: "P: - previous game against opponent - Ex. Home teams that won the last meeting as a home dog", url: "P:HDW and H"},
        {name: "n: - next game - Ex. next game is more than a week from today", url: "n:date>today+7"},
        {name: "on: - opponent's next game - Ex. Home teams against team off an away game and has an away game next", url: "op:A and H and on:A"},
        {name: "N: - next game against opponent - Ex. Home team's that will be away the next meeting", url: "H and N:A"},
    ]
}

export {docs};