import styled from "styled-components";

export const ProductList = styled("div")`
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */

    /* margin: 50px; */
    gap: 25px;
    padding: 20px;
    max-width: 1500px;
    margin: auto;
    margin-top: 150px;

    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
`;

export const MainCont = styled("div")`
    display: grid;
    place-items: center;
    margin-top: 150px;
`;

export const Heading = styled("h1")`
    color: red;
    
`;