import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getUserData } from "./Apis/UserApi";
import "./App.css";
import Home from "./Component/Home";
import Login from "./Component/Login";
import SavedQueriesPage from "./Component/SavedQueriesPage";
import Signup from "./Component/Signup";
import EditProfilePage from "./pages/EditProfilePage";
import GeneralUseQueriesPage from "./pages/GeneralUseQueriesPage";
import MartPage from "./pages/MartPage";
import MartSellersPage from "./pages/MartSellersPage";
import PaymentCancelPage from "./pages/PaymentCancelPage";
import PaymentSuccessfulPage from "./pages/PaymentSuccessfulPage";
import ProcessQueryPage from "./pages/ProcessQueryPage";
import SellerProfilePage from "./pages/SellerProfilePage";
import TodaysQueriesPage from "./pages/TodaysQueriesPage";
import { loginAction } from "./redux/MyActions";
import { URL } from "./Urls";
import QueryResultPage from "./pages/QueryResultPage";
import PaymentPage from "./pages/PaymentPage";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function App(props) {
    //const [open, setOpen] = useState(false);

    const [open, setOpen] = useState(() => {
        if (localStorage.getItem("docsTab")) {
            return false;
        } else {
            return true;
        }
    });

    setTimeout(() => localStorage.removeItem("docsTab"), 0);

    const classes = useStyles();

    const fetchUserData = async () => {
        const userData = await getUserData();
        if (userData) {
            props.doLogin(userData);
        }
    };

    useEffect(() => {
        axios({
            method: "post",
            //url: "https://sdql-testapp.herokuapp.com/isAuthenticated/",
            url: URL + "isAuthenticated/",
            data: {
                jwtCookie: props.jwtCookie,
            },
        }).then((res) => {
            //console.log("APP: ", res)
            if (res.data.message === "OK") {
                props.doLogin();
                fetchUserData();
            }
        });
        //setOpen(true)
    }, []);

    return (
        <Router>
            <main className="App">
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>

                    <Route path="/login">
                        <Login />
                    </Route>

                    <Route path="/signup">
                        <Signup />
                    </Route>

                    <Route path="/saved-queries">
                        <SavedQueriesPage />
                    </Route>

                    <Route path="/profile">
                        <EditProfilePage />
                    </Route>

                    <Route path="/seller/:sellerId">
                        <SellerProfilePage />
                    </Route>

                    <Route path="/mart/user/:userId/">
                        <MartPage />
                    </Route>

                    <Route path="/mart/all/">
                        <MartPage />
                    </Route>

                    <Route path="/mart">
                        <MartSellersPage />
                    </Route>

                    <Route path="/payment_successful">
                        <PaymentSuccessfulPage />
                    </Route>

                    <Route path="/payment_cancel">
                        <PaymentCancelPage />
                    </Route>

                    <Route path="/general-queries/:sportType/">
                        <GeneralUseQueriesPage />
                    </Route>

                    <Route path="/todays-queries/:sportType/">
                        <TodaysQueriesPage />
                    </Route>

                    <Route path="/processed-query/:queryId/">
                        <QueryResultPage />
                    </Route>

                    <Route path="/payment/:martItemId/">
                        <PaymentPage />
                    </Route>

                    <Route path="/:sportType/">
                        <ProcessQueryPage />
                    </Route>

                </Switch>
            </main>
        </Router>
    );
}

const mapStateToProps = (state) => {
    return {
        jwtCookie: state.jwtCookie,
        authState: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        doLogin: (payload) => dispatch(loginAction(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
