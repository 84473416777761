import {
  Box, CircularProgress, Tab
} from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  deactivateSavedQuery,
  deleteSavedQuery,
  getSavedQueries
} from "../Apis/QueryApi";
import { getUserData } from "../Apis/UserApi";
import { processSavedQuery } from "../Apis/QueryApi";
import AddForSellModal from "./Modals/AddForSellModal";
import CreateQueryModal from "./Modals/CreateQueryModal";
import EditQueryModal from "./Modals/EditQueryModal";
import ImportQueryModal from "./Modals/ImportQueryModal";
import Navbar from "./Navbar";
import QueriesTable from "./QueriesTable";
import RemoveFromSellModal from "./Modals/RemoveFromSellModal";
import DeleteSelectedQueriesModal from "./Modals/DeleteSelectedQueriesModal";


export const LoaderCont = styled("div")`
  display: grid;
  place-items: center;
  margin-top: 50px;
`;

const SavedQueriesPage = (props) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openImportModal, setOpenImportModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [canSell, setCanSell] = React.useState(false);


  const handleQueryImportOpen = () => setOpenImportModal(true);
  const handleQueryImportClose = () => setOpenImportModal(true);
  const handleOpen = () => setOpenCreateModal(true);
  const handleClose = () => setOpenCreateModal(false);

  const [queriesData, setQueriesData] = React.useState([]);
  const [filteredQueriesData, setFilteredQueriesData] = React.useState([]);
  const [showActiveQueriesState, setShowActiveQueriesState] = React.useState(false);
  const [currentEditQuery, setCurrentEditQuery] = React.useState({});
  const [tabValue, setTabValue] = React.useState("NFL");
  const [selectedQueries, setSelectedQueries] = React.useState([]);

  const [openMonetizeModal, setOpenMonetizeModal] = React.useState(false);
  const [openRemoveFromSellModal, setOpenRemoveFromSellModal] = React.useState(false);
  const [currentMonetizeQuery, setCurrentMonetizeQuery] = React.useState({});

  const handleQuerySelect = (queryId, remove) => {
    if (!remove) {
      setSelectedQueries([...new Set([...selectedQueries, queryId])]);
    } else {
      const newSelectedQueries = selectedQueries.filter(
        (val) => val !== queryId
      );
      setSelectedQueries([...new Set([...newSelectedQueries])]);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async () => {
    setLoading(true);
    const data = await getSavedQueries(tabValue);
    console.log(data)
    if (data) {
      setQueriesData(data);
    }
    setLoading(false);
  };

  const fetchUserData = async () => {
    const data = await getUserData();
    if (data) {
      console.log(data)
      setCanSell(data?.can_sell);
    }
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal)
  };

  const handleEditOpen = (queryId) => {
    const currentQuery = queriesData.filter((query) => query.id === queryId)[0];
    if (currentQuery) {
      setCurrentEditQuery(currentQuery);
      console.log(currentQuery);
      setOpenEditModal(true);
    }
  };

  const handleMonetizeOpen = (queryId) => {
    if (!canSell) return;
    const currentQuery = queriesData.filter((query) => query.id === queryId)[0];
    if (currentQuery) {
      setCurrentMonetizeQuery(currentQuery);
      setOpenMonetizeModal(true);
    }
  };

  const handleRemoveFromSellOpen = (queryId) => {
    const currentQuery = queriesData.filter((query) => query.id === queryId)[0];
    if (currentQuery) {
      setCurrentMonetizeQuery(currentQuery);
      setOpenRemoveFromSellModal(true);
    }
  };

  const handleProcessQuery = async () => {
    if (selectedQueries.length <= 0) return;
    setLoading(true)
    await processSavedQuery({ data: selectedQueries });
    await fetchData();
    setSelectedQueries([]);
    setLoading(false)
  };

  const handleDeactivateQuery = async () => {
    if (selectedQueries.length <= 0) return;
    setLoading(true)
    await deactivateSavedQuery({ data: selectedQueries });
    await fetchData();
    setSelectedQueries([]);
    setLoading(false)
  };

  const handleShowActiveQueries = () => {
    const filteredData = queriesData.filter(({ next_active_date }) => new Date(new Date(Number(next_active_date) * 1000).toDateString()) >= new Date(new Date().toDateString())); 
    setFilteredQueriesData(filteredData)
    setShowActiveQueriesState(!showActiveQueriesState);
  };

  useEffect(() => {
    setQueriesData([]);
    setSelectedQueries([]);
    fetchData();
  }, [tabValue]);

  useEffect(() => {
    if (!props.store.isAuthenticated) {
      history.replace("/login")
    }
    fetchUserData();
  }, []);

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "120px" }}>
        <div className="d-flex mx-4">
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleOpen()}
          >
            Add New SDQL
          </button>
          <button
            className="btn btn-danger btn-sm ms-3"
            onClick={() => handleQueryImportOpen()}
          >Import SDQLs</button>
          <button
            className="btn btn-success btn-sm ms-3"
            onClick={() => handleProcessQuery()}
            disabled={loading || selectedQueries.length <= 0 ? true : false}
          >Start SDQLs</button>
          {selectedQueries.length > 0 && (
            <button
              className="btn btn-danger btn-sm ms-3"
              onClick={() => handleDeactivateQuery()}
              disabled={loading || selectedQueries.length <= 0 ? true : false}
            >Deactivate SDQLs</button>
          )}
          <button
            className="btn btn-primary btn-sm ms-3"
            onClick={() => handleShowActiveQueries()}
          >
            {showActiveQueriesState ? "Show all queries" : "Show Today's Active Queries"}
          </button>
        </div>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", maxWidth: "100vw" }}>
            <TabList onChange={handleChange} variant="scrollable">
              <Tab label="NFL" value="NFL" />
              <Tab label="NCAAFB" value="NCAAFB" />
              <Tab label="NBA" value="NBA" />
              <Tab label="MLB" value="MLB" />
              <Tab label="NHL" value="NHL" />
              <Tab label="NCAABB" value="NCAABB" />
              <Tab label="CFL" value="CFL" />
              <Tab label="WNBA" value="WNBA" />
              <Tab label="UFC" value="UFC" />
              <Tab label="UFCW" value="UFCW" />
              <Tab label="ATP" value="ATP" />
              <Tab label="NFLPRE" value="NFLPRE" />
            </TabList>
          </Box>
          <Box
            sx={{
              margin: "30px auto",
              maxWidth: "1600px",
              padding: "0px 20px",
            }}
          >
            <div className="mb-3 text-end">
              {selectedQueries.length > 0 && (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDeleteModal()}
                  disabled={loading}
                >
                  Delete Selected SDQLs
                </button>
              )}
            </div>

            {loading ? (
              <LoaderCont>
                <CircularProgress />
              </LoaderCont>
            ) : (
              <QueriesTable
                data={showActiveQueriesState ? filteredQueriesData : queriesData}
                tabValue={tabValue}
                handleQuerySelect={handleQuerySelect}
                handleEditOpen={handleEditOpen}
                handleMonetizeOpen={handleMonetizeOpen}
                handleRemoveFromSellOpen={handleRemoveFromSellOpen}
                canSell={canSell}
              />
            )}
          </Box>
        </TabContext>
      </div>

      <DeleteSelectedQueriesModal
        fetchData={fetchData}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        selectedQueries={selectedQueries}
      />

      <CreateQueryModal
        fetchData={fetchData}
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        tabValue={tabValue}
      />

      <ImportQueryModal
        fetchData={fetchData}
        open={openImportModal}
        setOpen={setOpenImportModal}
      />

      <EditQueryModal
        fetchData={fetchData}
        open={openEditModal}
        setOpen={setOpenEditModal}
        currentEditQuery={currentEditQuery}
        setCurrentEditQuery={setCurrentEditQuery}
      />

      <AddForSellModal
        fetchData={fetchData}
        open={openMonetizeModal}
        setOpen={setOpenMonetizeModal}
        currentMonetizeQuery={currentMonetizeQuery}
        setCurrentMonetizeQuery={setCurrentMonetizeQuery}
      />

      <RemoveFromSellModal
        fetchData={fetchData}
        open={openRemoveFromSellModal}
        setOpen={setOpenRemoveFromSellModal}
        currentMonetizeQuery={currentMonetizeQuery}
        setCurrentMonetizeQuery={setCurrentMonetizeQuery}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

const mapDispatchToProps = (dispatch) => {
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedQueriesPage);
