import { Box, Menu, MenuItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AccountCircle, More } from "@material-ui/icons";
import AppBar from "@mui/material/AppBar";
import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie/es6";
import "../CSS/Navbar.css";
import navbarLogo from "../Images/GTD_adobespark.png";
import { logoutAction } from "../redux/MyActions";
import { URL } from "../Urls";

const Navbar = (props) => {
  const history = useHistory();
  const location = useLocation()
  const [activeLink, setActiveLink] = useState(location.pathname.split("/")[1]);

  const [logIn, setLogIn] = useState(false);
  const [logOut, setLogOut] = useState(false);

  const handleLogin = () => {
    history.push("/login");
  };

  const handleLogout = () => {
    axios({
      method: "get",
      //url: "https://sdql-testapp.herokuapp.com/logout/",
      url: URL + "logout/",
      //withCredentials: true,
    }).then((res) => {
      if (res.data.message === "Logged Out") {
        const cookies = new Cookies();
        cookies.remove("jwt");
        props.doLogout();
        history.replace("/login");
      }
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => history.push("/profile")}>
        <span>Edit Profile</span>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/mart/user/${props?.userData?.id}/`)}>
        <span>My Mart</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/saved-queries")}>
        <span>Saved Queries</span>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose()}>References</MenuItem>
      {props.isAuthenticated && (
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      )}
      {!props.isAuthenticated && (
        <MenuItem onClick={handleLogin}>Login</MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => history.push("/mart")}>
        <span>GTD Market</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/NFL")}>
        <span>NFL</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/NCAAFB")}>
        <span>NCAAFB</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/NBA")}>
        <span>NBA</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/NHL")}>
        <span>NHL</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/MLB")}>
        <span>MLB</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/WNBA")}>
        <span>WNBA</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/NFLPRE")}>
        <span>NFLPRE</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/CFL")}>
        <span>CFL</span>
      </MenuItem>
      <MenuItem onClick={() => history.push("/UFC")}>
        <span>UFC</span>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <span>Others</span>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    setActiveLink(location.pathname.split("/")[1]);
  }, [location])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "black",
          height: 100,
          justifyContent: "center",
        }}
        sx={{ border: 3, borderColor: "red" }}
      >
        <Toolbar>
          <Typography
            variant="h4"
            component="div"
            sx={{ display: { xs: "block", sm: "block" } }}
          >
            {/* <Link class="nav-link" to="/"><span className="myLogo">SDQL</span></Link> */}
            <Link class="nav-link" to="/">
              <img
                src={navbarLogo}
                style={{ width: 100, height: 100 }}
                alt="BigCo Inc. logo"
              />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "space-around",
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              <Box sx={{ flexGrow: 1 }}></Box>

              <Link class="nav-link myNavItem" to="/mart">
                <span
                  className={`pageName ${activeLink === "mart" ? "pageNameActive" : ""}`}
                >
                  GTD Market
                </span>
              </Link>

              <Link class="nav-link myNavItem" to="/NFL">
                <span
                  className={`pageName ${activeLink === "NFL" ? "pageNameActive" : ""}`}
                >
                  NFL
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/NCAAFB">
                <span
                  className={`pageName ${activeLink === "NCAAFB" ? "pageNameActive" : ""}`}
                >
                  NCAAFB
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/NBA">
                <span
                  className={`pageName ${activeLink === "NBA" ? "pageNameActive" : ""}`}
                >
                  NBA
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/NHL">
                <span
                  className={`pageName ${activeLink === "NHL" ? "pageNameActive" : ""}`}
                >
                  NHL
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/MLB">
                <span
                  className={`pageName ${activeLink === "MLB" ? "pageNameActive" : ""}`}
                >
                  MLB
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/NCAABB">
                <span
                  className={`pageName ${activeLink === "NCAABB" ? "pageNameActive" : ""}`}
                >
                  NCAABB
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/WNBA">
                <span
                  className={`pageName ${activeLink === "WNBA" ? "pageNameActive" : ""}`}
                >
                  WNBA
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/NFLPRE">
                <span
                  className={`pageName ${activeLink === "NFLPRE" ? "pageNameActive" : ""}`}
                >
                  NFLPRE
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/ATP">
                <span
                  className={`pageName ${activeLink === "ATP" ? "pageNameActive" : ""}`}
                >
                  ATP
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/CFL">
                <span
                  className={`pageName ${activeLink === "CFL" ? "pageNameActive" : ""}`}
                >
                  CFL
                </span>
              </Link>

              <Link class="nav-link myNavItem" to="/UFC">
                <span
                  className={`pageName ${activeLink === "UFC" ? "pageNameActive" : ""}`}
                >
                  UFC
                </span>
              </Link>
              <Link class="nav-link myNavItem" to="/UFCW">
                <span
                  className={`pageName ${activeLink === "UFCW" ? "pageNameActive" : ""}`}
                >
                  UFCW
                </span>
              </Link>

              <Box sx={{ flexGrow: 1 }}></Box>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <More />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated,
    userData: { ...state },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLogout: () => dispatch(logoutAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
