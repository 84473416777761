import { TextField, Typography } from "@material-ui/core";
import styled from "styled-components";

export const MainBody = styled("div")`
    display: flex;
    width: 100%;

    max-width: 1500px;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding: 0px 20px;
`;

export const Content = styled("div")`
    flex: 1 0 100%;
`;

export const ProductList = styled("div")`
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */

    /* margin: 50px; */
    gap: 25px;
    padding: 0px 20px;
    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
`;

export const LoaderCont = styled("div")`
    display: grid;
    place-items: center;
    margin-top: 150px;
`;

export const Heading = styled("h2")`
    font-size: 2.2rem;
    color: red;
`;
